import { Box, Card, CardContent, Grid, Link, Typography, CardHeader } from '@mui/material';
import Spinner from 'components/Common/Spinner';
import { useHttp } from 'hooks/use-fetch';
import NoData from 'pages/Dashboard/components/NoData';
import UserAddedToolTip from 'pages/Dashboard/components/UserAddedToolTip';
import HealthRecordCategories from 'pages/Dashboard/HealthRecordCategories';
import { fetchLabListReport } from 'pages/Dashboard/services/labs.services';
import {
  ShowMoreButton,
  ShowMoreDrawerChildProps,
  ShowMoreDrawerTitle,
  sliceForShowMore,
} from 'pages/Dashboard/ShowMoreDrawer';
import { LabReport, LabReportAPIResponse } from 'pages/Dashboard/types/lab.types';
import * as React from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import theme from 'theme';
import { googleDateToDateString } from 'utils/dateUtils';
import { downloadUrl } from 'utils/service';

export interface LabCardProps extends ShowMoreDrawerChildProps {
  labReports?: LabReport[];
  isNewUi?:boolean
}

interface LabDetailsProps {
  labData: LabReport[];
  reportResponseIgnite: boolean;
  fetchPdf: (labId: string) => Promise<void>;
}

function LabDetails({ labData, reportResponseIgnite, fetchPdf }: LabDetailsProps): JSX.Element {
  const nonDatedLabReports = labData.filter((labs) => !labs.date);
  return (
    <>
      {labData.map((report) => {
        if (!report.date) return null;
        return (
          <Box key={`lab-${report.id}`} mb={2} display='flex' flexDirection='row' justifyContent='space-between' width='100%'>
            <Box display='flex' flexDirection='column'>
              <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>{googleDateToDateString(report.date)}</Typography>
              <Typography>{report?.lab?.name}</Typography>
              {report.doctor && (
              <Typography variant='subtitle1' color={theme.custom.colors.lightTextSecondary}>
                {`${report.doctor.firstName} ${report.doctor.lastName}`}
              </Typography>
              )}
              <Link
                component='a'
                variant='subtitle1'
                underline='hover'
                sx={{ cursor: 'pointer' }}
                onClick={() => fetchPdf(report.id)}
              >
                {report.name}
              </Link>
            </Box>
            <UserAddedToolTip source={report.source} />
          </Box>
        );
      })}
      {nonDatedLabReports.length ? (
        <>
          <Typography mb={2} variant='subtitle1'>
            Unknown dates
          </Typography>
          {nonDatedLabReports.map((report) => (
            <Box key={`lab-${report.id}`} mb={2} display='flex' flexDirection='row' justifyContent='space-between' width='100%'>
              <Box display='flex' flexDirection='column'>
                <Typography>{report?.lab?.name}</Typography>
                {report.doctor && (
                <Typography variant='subtitle1'>
                  {`${report.doctor.firstName} ${report.doctor.lastName}`}
                </Typography>
                )}
                {reportResponseIgnite ? (
                  <Spinner color='secondary' />
                ) : (
                  <Link
                    component='a'
                    variant='subtitle1'
                    underline='hover'
                    sx={{ cursor: 'pointer' }}
                    onClick={() => fetchPdf(report.id)}
                  >
                    {report.name}
                  </Link>
                )}
              </Box>
              <UserAddedToolTip source={report.source} />
            </Box>
          ))}
        </>
      ) : null}
    </>
  );
}

export default function LabCard({
  closeDrawer,
  drawerItem,
  drawerMode = false,
  labReports = [],
  isNewUi = false,
  onShowMore,
}: LabCardProps): JSX.Element | null {
  const { id } = useParams();
  const { http, providerId } = useHttp();
  const [fetchLabReportResponse, setFetchLabReportResponse] = React.useState<LabReportAPIResponse>(
    {} as LabReportAPIResponse,
  );

  const datedLabReports = labReports.filter((labs) => labs.date);
  const nonDatedLabReports = labReports
    .filter((labs) => !labs.date)
    .sort((a, b) => a.lab.name.localeCompare(b.lab.name));

  const sortedLabReports = [...datedLabReports, ...nonDatedLabReports];
  const data = sliceForShowMore(sortedLabReports, !drawerMode);

  const { mutate: fetchLabReport, isLoading } = useMutation({
    mutationFn: (labId: string) => fetchLabListReport(http.post, providerId, id as string, labId),
    onSettled: (d) => {
      setFetchLabReportResponse(d);
    },
  });

  React.useEffect(() => {
    if (fetchLabReportResponse?.signed_url && !isLoading) {
      downloadUrl(fetchLabReportResponse?.signed_url, '', true);
    }
  }, [fetchLabReportResponse?.signed_url, isLoading]);

  const fetchPdf = async (labId: string) => {
    fetchLabReport(labId);
  };

  const { Icon } = HealthRecordCategories.labs.value;
  if (labReports.length === 0 && isNewUi) {
    return null;
  }
  return (

    <Grid item xs={12} md={!drawerMode ? 4 : undefined} key='labs'>
      <Card sx={{ height: '100%' }}>
        {!drawerMode && (
          <CardHeader
            avatar={<Icon />}
            title='Labs'
            titleTypographyProps={{ variant: 'h5', fontSize: 24, fontWeight: 400 }}
          />
        )}
        {drawerMode && <ShowMoreDrawerTitle closeDrawer={closeDrawer} drawerItem={drawerItem} />}
        <CardContent>
          {!labReports.length ? (
            <NoData />
          ) : (
            <LabDetails
              labData={data}
              reportResponseIgnite={isLoading || false}
              fetchPdf={fetchPdf}
            />
          )}
          <ShowMoreButton
            isDrawer={drawerMode}
            onShowMore={onShowMore as VoidFunction}
            items={labReports}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}
