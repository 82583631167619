import { Box, Card, CardContent, Grid, Stack, Typography, CardHeader } from '@mui/material';
import NoDataForNow from 'pages/Dashboard/components/NoDataForNow';
import UserAddedToolTip from 'pages/Dashboard/components/UserAddedToolTip';
import HealthRecordCategories from 'pages/Dashboard/HealthRecordCategories';
import { RelationFormatter, EducationFormatter, LegalHistoryFormatter, consumption } from 'pages/Dashboard/services/lifestyle.services';
import { EducationLevel, LegalHistoryType, PatientBackground, PatientBackgroundCategory, PatientRelationshipCategory } from 'pages/Dashboard/types/background.types';
import { Exercise, LifeStyleCategory, PatientLifeStyle } from 'pages/Dashboard/types/lifestyle.types';
import * as React from 'react';
import theme from 'theme';


interface PsychosocialCardProps {
  lifeStyle?: PatientLifeStyle[],
  background?: PatientBackground[]
  isNewUi?:boolean
}

export default function PsychosocialCard(
  { lifeStyle = [], background = [], isNewUi = false }: PsychosocialCardProps,
): JSX.Element | null {
  const noPsychologicalDataPresent = lifeStyle.length > 0 || background.length > 0;
  if (!noPsychologicalDataPresent && isNewUi) {
    return null;
  }
  function renderBackground(currentBackground: PatientBackground) {
    const { relationship, education, legalHistory } = currentBackground;
    switch (currentBackground.category) {
      case PatientBackgroundCategory.PATIENT_BACKGROUND_CATEGORY_RELATIONSHIP:
        return (
          <Box display='flex' width='100%' justifyContent='space-between' alignItems='center'>
            <Box display='flex' flexDirection='column'>
              <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
                Relationship
              </Typography>
              <Typography>
                {RelationFormatter(relationship?.relationshipStatus
                || PatientRelationshipCategory.PATIENT_RELATIONSHIP_CATEGORY_UNSPECIFIED)}
              </Typography>
            </Box>
            <UserAddedToolTip source={relationship?.source ?? ''} />
          </Box>
        );
      case PatientBackgroundCategory.PATIENT_BACKGROUND_CATEGORY_EDUCATION:
        return (
          <Box display='flex' width='100%' justifyContent='space-between' alignItems='center'>
            <Box display='flex' flexDirection='column'>
              <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
                Education
              </Typography>
              <Typography>
                {EducationFormatter(education?.educationLevel
                || EducationLevel.EDUCATION_LEVEL_UNSPECIFIED)}
              </Typography>
            </Box>
            <UserAddedToolTip source={education?.source ?? ''} />
          </Box>

        );
      case PatientBackgroundCategory.PATIENT_BACKGROUND_CATEGORY_LEGAL_HISTORY:
        return (
          <Box display='flex' width='100%' justifyContent='space-between' alignItems='center'>
            <Box display='flex' flexDirection='column'>
              <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
                Legal history
              </Typography>
              <Typography>
                {
                legalHistory?.legalHistory
                  ? LegalHistoryFormatter(legalHistory?.legalHistoryType
                    || LegalHistoryType.LEGAL_HISTORY_TYPE_UNSPECIFIED)
                  : 'Never been arrested'
              }
              </Typography>
            </Box>
            <UserAddedToolTip source={legalHistory?.source ?? ''} />
          </Box>
        );
    }
    return null;
  }
  const { Icon } = HealthRecordCategories.psychosocial.value;
  return (
    <Grid item xs={12} md={4}>
      <Card sx={{ height: '100%' }}>
        <CardHeader
          avatar={<Icon />}
          title={HealthRecordCategories.psychosocial.value.displayName}
          titleTypographyProps={{ variant: 'h5', fontSize: 24, fontWeight: 400 }}
        />
        <CardContent>
          {
            !lifeStyle.find(
              (style) => style.category === LifeStyleCategory.LIFE_STYLE_CATEGORY_EXERCISE,
            ) && !background.length ? <NoDataForNow /> : (
              <Stack spacing={2}>
                { lifeStyle.map((style) => {
                  if (style.category === LifeStyleCategory.LIFE_STYLE_CATEGORY_EXERCISE) {
                    const { exercise } = style;
                    return (
                      <Box key={LifeStyleCategory.LIFE_STYLE_CATEGORY_EXERCISE} display='flex' flexDirection='row' width='100%' justifyContent='space-between' bgcolor='red'>
                        <Box display='flex' flexDirection='column' bgcolor='red'>
                          <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
                            Exercise
                          </Typography>
                          {exercise?.consumption ? (
                            <>
                              {consumption(exercise)
                                ? <Typography>{consumption(exercise)}</Typography> : null }
                              {exercise?.exercises?.map((workout: Exercise) => (
                                <Typography key={workout.id}>{workout.name}</Typography>
                              ))}
                            </>
                          ) : 'None'}
                        </Box>
                        <UserAddedToolTip source={'SOURCE_TYPE_PATIENT_REPORTED' ?? ''} />
                      </Box>
                    );
                  }
                  return null;
                })}
                { background.map((curr) => renderBackground(curr))}
              </Stack>
            )
          }
        </CardContent>
      </Card>
    </Grid>
  );
}
