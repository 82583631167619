import { useHttp } from 'hooks/use-fetch';
import DashboardNew from 'pages/Dashboard/DashboardNew';
import DashboardV2 from 'pages/Dashboard/Dashboard';
import { fetchUserMetadataWithKeys } from 'pages/Dashboard/services/provider.services';
import { UserMetadata, UserMetadataFlags } from 'pages/Dashboard/types';
import React from 'react';
import { useQuery } from 'react-query';
import ContentLoader from 'components/Common/ContentLoader';

export default function DashboardUIContainer() {
  const { providerId, http } = useHttp();
  const [isNewUi, setIsNewUi] = React.useState<boolean>(false);
  const [showLoader, setShowLoader] = React.useState<boolean>(true);
  useQuery({
    queryKey: [providerId, UserMetadataFlags.EnableNewList],
    queryFn: fetchUserMetadataWithKeys(http.post),
    onSuccess: (data: UserMetadata) => {
      const isEnabled = data.flag.value === 'true';
      setIsNewUi(isEnabled);
      setShowLoader(false);
    },
    onError: () => {
      setIsNewUi(false);
      setShowLoader(false);
    },
  });

  return (
    <ContentLoader isFetching={showLoader} isError={false} minHeight={1000}>
      {isNewUi ? <DashboardNew /> : <DashboardV2 />}
    </ContentLoader>
  );
}
