import {
  ListMentalHealthProvidersAPIResponse,
  ListPrimaryCareProvidersAPIResponse,
  ProvidersAPIResponse,
} from 'pages/Dashboard/types/providers.types';
import { QueryFunctionContext } from 'react-query';
import { patientProviderUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

export const fetchPatientProvidersList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<ProvidersAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return (
    method(patientProviderUrls.listPatientProviders.apiUrls(
      providerId as string,
      patientId as string,
    ).list)
  );
};

export const fetchAllPatientProvidersList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<ProvidersAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return (
    method(patientProviderUrls.listPatientProviders.apiUrls(
      providerId as string,
      patientId as string,
    ).listAll)
  );
};


export const fetchPatientCurrentProvidersList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<ProvidersAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return (
    method(patientProviderUrls.listPatientProviders.apiUrls(
      providerId as string,
      patientId as string,
    ).listCurrent)
  );
};

export const fetchPatientPrimaryCareProviderList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<ListPrimaryCareProvidersAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return (
    method(patientProviderUrls.listPatientPrimaryCareProviders.apiUrls(
      providerId as string,
      patientId as string,
    ).list)
  );
};

export const fetchPatientCurrentPrimaryCareProviderList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<ListPrimaryCareProvidersAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return (
    method(patientProviderUrls.listPatientPrimaryCareProviders.apiUrls(
      providerId as string,
      patientId as string,
    ).listCurrent)
  );
};

export const fetchPatientMentalHealthProviderList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<ListMentalHealthProvidersAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return (
    method(patientProviderUrls.listPatientMentalHealthProviders.apiUrls(
      providerId as string,
      patientId as string,
    ).list)
  );
};

export const fetchPatientCurrentMentalHealthProviderList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<ListMentalHealthProvidersAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return (
    method(patientProviderUrls.listPatientMentalHealthProviders.apiUrls(
      providerId as string,
      patientId as string,
    ).listCurrent)
  );
};
