import React, { useEffect, useState } from 'react';
import { Box, Drawer, Typography } from '@mui/material';
import TimelineTable from 'pages/Dashboard/components/RefillTimelineView';
import { Close } from '@mui/icons-material';
import Tabs from 'pages/Dashboard/components/Tabs';
import Theme from 'theme';
import { useQuery } from 'react-query';
import { medicationsUrls } from 'utils/apiUrls';
import { fetchRefills } from 'pages/Dashboard/services/medications.services';
import { useHttp } from 'hooks/use-fetch';
import { Refills } from 'pages/Dashboard/types/medication.types';
import { googleDateToDateString } from 'utils/dateUtils';
import ContentLoader from 'components/Common/ContentLoader';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';
import RefillChartAlternate from 'pages/Dashboard/components/RefillChartAlternate';

interface MyDrawerProps {
  isOpen: boolean;
  toggleDrawer: (open: boolean) => void;
  medicationId: string;
  medicationName: string;
  medicationMetaId: string;
  patientId: string;
}

const metaDataArray = ['lastFillDate', 'startDate', 'provider', 'quantity'];
const displayNameMap: { [key: string]: string } = {
  lastFillDate: 'Last fill date',
  startDate: 'Start date',
  provider: 'Prescriber',
  dosageString: 'Dosage',
  quantity: 'Quantity',
};

export enum TabLabels {
  PSYCH = 'Chart',
  OTHER = 'Table',
}
const addReadableDateFieldToData = (refills: Refills[]) => refills.map((item) => {
  const startDate = item.dispensedOn ? googleDateToDateString(item.dispensedOn) : null;
  const epoch = startDate
    ? new Date(item.dispensedOn.year, item.dispensedOn.month - 1, item?.dispensedOn?.day)
    : null;
  const dosageString = item?.dosage ? `${item?.dosage} ${item?.dosageUnit}` : 'unknown';
  const providerName = item?.provider
    ? `${item?.provider?.firstName} ${item?.provider?.lastName}`
    : '';
  return {
    ...item,
    readableStartDate: startDate || 'unknown',
    dispensedDate: epoch ? epoch.getTime() / 1000 : undefined,
    dosageString,
    providerName,
    doctor: item?.provider,
  };
});

function addSuperscriptR(str: string) {
  const medName = str?.trim();
  return medName.replace(/\(([^)]+)\)$/, '($1<sup>®</sup>)');
}

const findQuantity = (refills: Refills[]) => {
  const quantityRefill = refills?.find((refill) => refill.quantity);
  return quantityRefill?.quantity;
};
function MyDrawer({
  isOpen,
  toggleDrawer,
  medicationId,
  medicationName,
  medicationMetaId,
  patientId,
}: MyDrawerProps) {
  const handleDrawerClose = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown'
      && ((event as React.KeyboardEvent).key === 'Tab'
        || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    trackMixpanelEvent(MixpanelEventName.HISTORY_DRAWER_CLOSED);
    setSelectedTab(0);
    toggleDrawer(false);
  };
  const [selectedTab, setSelectedTab] = useState(0);
  const [finalRefillData, setFinalRefillData] = useState<Refills[]>([]);
  const [dataWithKnownQuantity, setDataWithKnownQuantity] = useState<Refills[]>([]);
  const handleSelectedTab = (tab: number) => {
    trackMixpanelEvent(
      tab === 0
        ? MixpanelEventName.HISTORY_SHOW_CHART_CLICKED
        : MixpanelEventName.HISTORY_SHOW_TIMELINE_CLICKED,
    );
    setSelectedTab(tab);
  };
  const { http, providerId } = useHttp();
  // const finalUnknownRefillData = addReadableDateFieldToData(unknownDateRefills);
  const showChart = selectedTab === 0;
  const isMedicationMetaIdPresent = medicationMetaId?.length > 0;
  const medicationIdentification = isMedicationMetaIdPresent ? medicationMetaId : medicationId;
  const identifierType = isMedicationMetaIdPresent ? 'meta' : 'pmi';
  const refillsData = useQuery({
    queryKey: [medicationsUrls.refills.queryUrl, patientId, medicationIdentification, providerId],
    queryFn: fetchRefills(http.get, identifierType),
    enabled: !!patientId && !!medicationId && isOpen,
  });
  const refills = refillsData?.data?.patientMedicationRefills?.refills;
  const unknownDateRefills: Refills[] = refillsData
    ?.data?.patientMedicationRefills?.refillsWithoutDates ?? [];
  const finalUnknownRefillData = addReadableDateFieldToData(unknownDateRefills);
  const metaDataObject = {
    lastFillDate: finalRefillData?.[0]?.readableStartDate,
    startDate: finalRefillData?.[finalRefillData.length - 1]?.readableStartDate,
    provider: finalRefillData?.[0]?.providerName,
    quantity: findQuantity(finalRefillData),
    dosageString: finalRefillData?.[0]?.dosageString,
  };
  useEffect(() => {
    const finalData = addReadableDateFieldToData(refills || []);
    let dataForGraph: Refills[] = [];
    const temp: Refills[] = [];
    const set = new Set();
    finalData.forEach((refill) => {
      if (refill?.dosage?.length > 0 && refill?.dosageUnit?.length > 0) {
        temp.push(refill);
        set.add(refill.dosageUnit);
      }
    });
    if (set.size === 1) {
      dataForGraph = temp;
    }
    setFinalRefillData(finalData);
    setDataWithKnownQuantity(dataForGraph);
  }, [refills]);
  useEffect(() => {
    setSelectedTab(0);
  }, [isOpen]);
  useEffect(() => {
    if (dataWithKnownQuantity.length <= 2) {
      setSelectedTab(1);
    }
    else {
      setSelectedTab(0);
    }
  }, [dataWithKnownQuantity, isOpen]);

  const isMixedIngredient = refillsData?.data?.patientMedicationRefills?.isMultipleIngredient;
  // eslint-disable-next-line no-unsafe-optional-chaining
  const totalRefills = finalRefillData?.length + finalUnknownRefillData?.length;
  const isMedicationHistoryPresent = totalRefills > 1;
  const isChartPossible = dataWithKnownQuantity?.length > 1 && !isMixedIngredient;
  const timelineDataKeys = [
    {
      key: 'readableStartDate',
      headerText: 'Prescribed / refill date',
      width: 200,
    },
    {
      key: 'dosageString',
      headerText: 'Dose',
      width: 150,
    },
    {
      key: 'doctor',
      headerText: 'Prescriber',
      width: 250,
    },
  ];
  const medNameWithSuperscript = addSuperscriptR(medicationName);
  const renderInfo = () => {
    if (showChart) {
      return (
        <RefillChartAlternate
          refillsWithDate={dataWithKnownQuantity as Refills[]}
          unknownDateRefillsCount={unknownDateRefills?.length}
          medicationName={medicationName}
        />
      );
    }
    else {
      return (
        <>
          <TimelineTable
            data={finalRefillData}
            headers={['Date', 'Dose', 'Prescriber']}
            keys={['readableStartDate', 'dosageString', 'doctor']}
            widths={[200, 300, 250]}
            keyData={timelineDataKeys}
          />
          {finalUnknownRefillData?.length > 0 && (
            <>
              <Typography
                fontSize='16px'
                color={Theme.custom.colors.lightTextSecondary}
                ml={2}
                mt={3}
                mb={3}
              >
                Unknown dates
              </Typography>
              <TimelineTable
                data={finalUnknownRefillData as Refills[]}
                headers={['Date', 'Dose', 'Prescriber']}
                keys={['readableStartDate', 'dosageString', 'doctor']}
                widths={[160, 150, 250]}
                keyData={timelineDataKeys}
              />
            </>
          )}
        </>
      );
    }
  };
  return (
    <Drawer anchor='right' open={isOpen} onClose={handleDrawerClose}>
      <Box sx={{ minWidth: isChartPossible ? 900 : 600, maxHeight: 900 }}>
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          p={2}
          pb={3}
          mt={1}
          mb={1}
          alignItems='center'
          justifyContent='space-between'
          height={10}
          sx={{
            borderStyle: 'solid',
            borderColor: Theme.custom.colors.backgroundColorSecondary,
            borderWidth: '0px',
            borderBottomWidth: '1px',
          }}
        >
          <Typography fontSize={20} fontWeight={700}>
            Medication History
          </Typography>
          <Close sx={{ color: '#0000008A' }} onClick={handleDrawerClose} />
        </Box>
        <Box
          role='presentation'
          sx={{
            marginTop: 2,
            pl: 2,
            pr: 2,
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            minWidth: 300,
          }}
        >
          <Typography
            fontSize={24}
            fontWeight={400}
            maxWidth={600}
            style={{
              maxWidth: 600,
            }}
            dangerouslySetInnerHTML={{
              __html: medNameWithSuperscript,
            }}
          />
        </Box>
        <ContentLoader isFetching={refillsData.isLoading} minHeight={400} isError={false}>
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                mt: 3,
                ml: 2,
              }}
            >
              {metaDataArray.map((metaData) => {
                if (metaDataObject[metaData as keyof typeof metaDataObject]) {
                  return (
                    <Box
                      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 0 }}
                    >
                      <Typography
                        fontSize={16}
                        fontWeight={400}
                        color={Theme.custom.colors.lightTextSecondary}
                        width={120}
                        textAlign='left'
                      >
                        {displayNameMap[metaData]}
                        {' '}
                      </Typography>
                      <Typography
                        color='#1E2731'
                        display='inline-block'
                        textAlign='left'
                        fontSize={16}
                      >
                        {metaDataObject[metaData as keyof typeof metaDataObject]}
                      </Typography>
                    </Box>
                  );
                }
                return null;
              })}
            </Box>
            <>
              <Box
                display='flex'
                padding={2}
                justifyContent='space-between'
                alignItems='center'
                mt={3}
              >
                <Typography fontSize={20} fontWeight={700} textAlign='center'>
                  History
                </Typography>
                {isChartPossible ? (
                  <Box width={200} ml={16}>
                    <Tabs
                      tabItems={[
                        {
                          label: `${TabLabels.PSYCH}`,
                          value: 0,
                        },
                        { label: `${TabLabels.OTHER}`, value: 1 },
                      ]}
                      selectedTab={selectedTab}
                      setSelectedTab={(tab) => handleSelectedTab(tab)}
                    />
                  </Box>
                ) : null}
              </Box>
              {!isMedicationHistoryPresent ? (
                <Typography ml={2} color={Theme.custom.colors.lightTextSecondary}>
                  No history available
                </Typography>
              ) : (
                renderInfo()
              )}
            </>
          </>
        </ContentLoader>
      </Box>
    </Drawer>
  );
}

export default MyDrawer;
