import React from 'react';
import { Box, Drawer, Typography } from '@mui/material';
import TimelineTable from 'pages/Dashboard/components/RefillTimelineView';
import { Close } from '@mui/icons-material';
import Theme from 'theme';
import { useQuery } from 'react-query';
import { diagnosisUrls } from 'utils/apiUrls';
import { useHttp } from 'hooks/use-fetch';
import { googleDateToDateString } from 'utils/dateUtils';
import ContentLoader from 'components/Common/ContentLoader';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';
import { capitalize } from 'utils/appUtils';
import { fetchDiagnosisHistory } from 'pages/Dashboard/services/diagnosis.services';
import { Diagnosis, Doctor } from 'pages/Dashboard/types/diagnosis.types';
import {
  findPreferredDiagnosisCode,
  getDiagnosisDescriptionV2,
} from 'utils/patientUtils';
import DoctorText from 'pages/Dashboard/components/DoctorText';

interface MyDrawerProps {
  isOpen: boolean;
  toggleDrawer: (open: boolean) => void;
  diagnosisId: string;
  diagnosisName: string;
  patientId: string;
}

type MetaDataType = {
  lastDiagnosisDate: string; // 'unknown' or a string representing a date
  initialDiagnosisDate: string; // 'unknown' or a string representing a date
  diagnosisType: string | undefined; // Could be a string or undefined
  clinic: string; // Always a string, default to an empty string
  doctor?: Doctor | null
};


const metaDataArray = [
  'lastDiagnosisDate',
  'initialDiagnosisDate',
  'diagnosisType',
  'diagnosedBy',
  'clinic',
  'doctor',
];
const displayNameMap: { [key: string]: string } = {
  lastDiagnosisDate: 'Latest diagnosis',
  initialDiagnosisDate: 'Initial diagnosis',
  diagnosisType: 'Type',
  clinic: 'Clinic',
  doctor: 'Diagnosed by',
};

export enum TabLabels {
  PSYCH = 'Chart',
  OTHER = 'Table',
}
const showMetadata = (metaData: string, metaDataObject: MetaDataType) => (metaData !== 'doctor' && metaDataObject[metaData as keyof typeof metaDataObject])
  || (metaData === 'doctor' && metaDataObject?.doctor?.name?.length);
const addReadableDateFieldToData = (diagnosis: Diagnosis[]) => diagnosis.map((item) => {
  const startDate = item.started ? googleDateToDateString(item.started) : null;
  const codes = findPreferredDiagnosisCode(item?.diagnosis?.codes);

  const diagnosisName = codes
    ? getDiagnosisDescriptionV2(codes, item?.diagnosis?.displayName)
    : item?.diagnosis?.displayName;
  const providerName = item?.doctor?.name;
  const providerDesignation = `${item?.doctor?.designation}`;
  const providerInfo = `${item?.doctor?.name} #  ${item?.doctor?.designation}`;
  return {
    ...item,
    readableStartDate: startDate || 'unknown',
    diagnosisName,
    providerName,
    providerDesignation,
    providerInfo,
    doctor: item?.doctor,
  };
});
const separateKnownDatesFromUnknowns = (diagnosis: Diagnosis[]) => {
  const knownDates = diagnosis.filter((item) => item?.started?.year);
  const unknownDates = diagnosis.filter(
    (item) => item.started === null || item.started?.year === null,
  );
  return [knownDates, unknownDates];
};
function DiagnosisDrawer({
  isOpen,
  toggleDrawer,
  diagnosisId,
  diagnosisName,
  patientId,
}: MyDrawerProps) {
  const handleDrawerClose = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown'
      && ((event as React.KeyboardEvent).key === 'Tab'
        || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    trackMixpanelEvent(MixpanelEventName.HISTORY_DRAWER_CLOSED);
    toggleDrawer(false);
  };

  const { http, providerId } = useHttp();

  const diagnosisHistoryData = useQuery({
    queryKey: [diagnosisUrls.history.queryUrl, patientId, diagnosisId, providerId],
    queryFn: fetchDiagnosisHistory(http.get),
    enabled: !!patientId && !!diagnosisId && isOpen,
  });

  const diagnosisHistory = diagnosisHistoryData?.data?.patientDiagnosisHistory.history;
  const finalDataHistory = addReadableDateFieldToData(diagnosisHistory ?? []);
  const [knownDates = [], unknownDates = []] = separateKnownDatesFromUnknowns(
    finalDataHistory as Diagnosis[],
  );
  const metaDataObject = {
    lastDiagnosisDate: knownDates?.[0]?.readableStartDate || 'unknown',
    initialDiagnosisDate: knownDates?.[knownDates.length - 1]?.readableStartDate || 'unknown',
    diagnosisType: finalDataHistory?.[0]?.diagnosisType,
    clinic: '',
    doctor: finalDataHistory?.[0]?.doctor,
  };
  const totalRefills = knownDates.length ?? 0 + unknownDates.length ?? 0;
  const isDiagnosisHistoryPresent = totalRefills > 1;
  const timelineKeyData = [
    {
      headerText: 'Date diagnosed',
      key: 'readableStartDate',
      width: 120,
    },
    {
      headerText: 'Diagnosed by',
      key: 'doctor',
      width: 300,
    },
  ];
  return (
    <Drawer anchor='right' open={isOpen} onClose={handleDrawerClose}>
      <Box sx={{ minWidth: 600, maxHeight: 900 }}>
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          p={2}
          pb={3}
          mt={1}
          mb={1}
          alignItems='center'
          justifyContent='space-between'
          height={10}
          sx={{
            borderStyle: 'solid',
            borderColor: Theme.custom.colors.backgroundColorSecondary,
            borderWidth: '0px',
            borderBottomWidth: '1px',
          }}
        >
          <Typography fontSize={20} fontWeight={700}>
            Diagnosis details
          </Typography>
          <Close sx={{ color: '#0000008A' }} onClick={handleDrawerClose} />
        </Box>
        <Box
          role='presentation'
          sx={{
            marginTop: 2,
            pl: 2,
            pr: 2,
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            minWidth: 300,
          }}
        >
          <Typography
            fontSize={24}
            fontWeight={400}
            maxWidth='100%'
            width='fit-content'
            style={{
              overflow: 'hidden',
              maxWidth: 600,
            }}
          >
            {capitalize(diagnosisName)}
          </Typography>
        </Box>
        <ContentLoader isFetching={diagnosisHistoryData.isLoading} minHeight={400} isError={false}>
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                mt: 3,
                ml: 2,
              }}
            >
              {metaDataArray.map((metaData) => {
                if (showMetadata(metaData, metaDataObject)) {
                  return (
                    <Box
                      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 0 }}
                      key={metaData}
                    >
                      <Typography
                        fontSize={16}
                        fontWeight={400}
                        color={Theme.custom.colors.lightTextSecondary}
                        width={120}
                        textAlign='left'
                      >
                        {displayNameMap[metaData]}
                      </Typography>
                      {metaData === 'doctor' ? (
                        <Box ml={1}>
                          <DoctorText
                            doctor={metaDataObject.doctor}
                          />
                        </Box>
                      ) : (
                        <Typography
                          color='#1E2731'
                          display='inline-block'
                          textAlign='left'
                          fontSize={16}
                          ml={1}
                        >
                          {metaDataObject[metaData as keyof typeof metaDataObject]}
                        </Typography>
                      )}
                    </Box>
                  );
                }
                return null;
              })}
            </Box>
            <Box
              display='flex'
              padding={2}
              justifyContent='space-between'
              alignItems='center'
              mt={3}
            >
              <Typography fontSize={20} fontWeight={700} textAlign='center'>
                History
              </Typography>
            </Box>
            {isDiagnosisHistoryPresent ? (
              <>
                <TimelineTable
                  data={knownDates as Diagnosis[]}
                  headers={['Date', 'Diagnosed by']}
                  keys={['readableStartDate', 'doctor']}
                  widths={[120, 300]}
                  keyData={timelineKeyData}
                />
                {unknownDates?.length > 0 && (
                  <>
                    <Typography
                      fontSize='16px'
                      color={Theme.custom.colors.lightTextSecondary}
                      ml={2}
                      mt={3}
                      mb={3}
                    >
                      Unknown dates
                    </Typography>
                    <TimelineTable
                      data={unknownDates as Diagnosis[]}
                      headers={['Date', 'Diagnosed by']}
                      keys={['readableStartDate', 'doctor']}
                      widths={[120, 300]}
                      keyData={timelineKeyData}
                    />
                  </>
                )}
              </>
            ) : (
              <Typography
                ml={2}
                color={Theme.custom.colors.lightTextSecondary}
              >
                No history available
              </Typography>
            )}
          </>
        </ContentLoader>
      </Box>
    </Drawer>
  );
}

export default DiagnosisDrawer;
