import Grid from '@mui/material/Grid';
import SectionHeader from 'components/Common/SectionHeader';
import AllergiesCard from 'pages/Dashboard/components/AllergiesCard';
import DiagnosisCard from 'pages/Dashboard/components/DiagnosisCard';
import FamilyHistoryCard from 'pages/Dashboard/components/FamilyHistoryCard';
import HospitalizationsCard from 'pages/Dashboard/components/HospitalizationsCard';
import LabCard from 'pages/Dashboard/components/LabCard';
import ProvidersCard from 'pages/Dashboard/components/ProvidersCard';
import PsychosocialCard from 'pages/Dashboard/components/PsychosocialCard';
import ShowMoreDrawer from 'pages/Dashboard/ShowMoreDrawer';
import * as React from 'react';
import Spinner from 'components/Common/Spinner';
import { MedicationAPIResponse, PatientMedication } from 'pages/Dashboard/types/medication.types';
import { Diagnosis, DiagnosisAPIResponse } from 'pages/Dashboard/types/diagnosis.types';
import SubstanceCard from 'pages/Dashboard/components/SubstanceCard';
import SymptomsCard from 'pages/Dashboard/components/SymptomsCard';
import { UseQueryResult } from 'react-query';
import { FamilyHistoryAPIResponse } from 'pages/Dashboard/types/familyHistory.types';
import { PatientSymptomsAPIResponse } from 'pages/Dashboard/types/symptoms.types';
import { AllergiesAPIResponse } from 'pages/Dashboard/types/allergies.types';
import { HospitalizationAPIResponse } from 'pages/Dashboard/types/hospitalizations.types';
import { ListMentalHealthProvidersAPIResponse, ListPrimaryCareProvidersAPIResponse, ProvidersAPIResponse } from 'pages/Dashboard/types/providers.types';
import { PatientLifeStyleAPIResponse } from 'pages/Dashboard/types/lifestyle.types';
import { PatientBackgroundAPIResponse } from 'pages/Dashboard/types/background.types';
import { LabAPIResponse } from 'pages/Dashboard/types/lab.types';
import MedicationCard from 'pages/Dashboard/components/MedicationCard';

enum EDrawerItem {
  SYMPTOMS = 'Symptoms',
  LABS = 'Labs',
  MEDICATION_HISTORY = 'Medication history',
  DIAGNOSIS_HISTORY = 'Diagnosis history',
  FAMILY_HISTORY = 'Family History',
  PROVIDER_HISTORY = 'Provider history',
  HOSPITALIZATIONS = 'Hospitalizations',
  ALLERGIES = 'Allergies',
}

interface IHealthRecordProps {
  diagnosis: UseQueryResult<DiagnosisAPIResponse>;
  medications: UseQueryResult<MedicationAPIResponse>;
  familyHistory: UseQueryResult<FamilyHistoryAPIResponse>;
  symptoms: UseQueryResult<PatientSymptomsAPIResponse>;
  currentSymptoms: UseQueryResult<PatientSymptomsAPIResponse>;
  allergies: UseQueryResult<AllergiesAPIResponse>;
  hospitalizations: UseQueryResult<HospitalizationAPIResponse>;
  providers: UseQueryResult<ProvidersAPIResponse>;
  primaryCareProviders: UseQueryResult<ListPrimaryCareProvidersAPIResponse>;
  mentalHealthProviders: UseQueryResult<ListMentalHealthProvidersAPIResponse>;
  lifeStyle: UseQueryResult<PatientLifeStyleAPIResponse>;
  backgrounds: UseQueryResult<PatientBackgroundAPIResponse>;
  labs: UseQueryResult<LabAPIResponse>;
}

export default function HealthRecord({
  diagnosis,
  medications,
  familyHistory,
  symptoms,
  currentSymptoms,
  allergies,
  hospitalizations,
  providers,
  primaryCareProviders,
  mentalHealthProviders,
  lifeStyle,
  backgrounds,
  labs,
}: IHealthRecordProps) {
  const { data: diagnosisResponse, isFetching: diagnosisFetching } = diagnosis;
  const { data: medicationResponse, isFetching: medicationFetching } = medications;
  const { data: currentSymptomsResponse, isFetching: currentSymptomsFetching } = currentSymptoms;
  const { data: symptomsResponse, isFetching: symptomsFetching } = symptoms;
  const { data: familyHistoryResponse, isFetching: familyHistoryFetching } = familyHistory;
  const { data: allergiesResponse, isFetching: allergiesFetching } = allergies;
  const { data: hospitalizationsResponse, isFetching: hospitalizationsFetching } = hospitalizations;
  const { data: providerResponse, isFetching: providerFetching } = providers;
  const { data: labsResponse, isFetching: labsFetching } = labs;
  const { data: lifeStyleResponse, isFetching: lifeStyleFetching } = lifeStyle;
  const { data: backgroundResponse, isFetching: backgroundFetching } = backgrounds;
  const {
    data: primaryCareProvidersResponse,
    isFetching: primaryCareProvidersFetching,
  } = primaryCareProviders;
  const {
    data: mentalHealthProvidersResponse,
    isFetching: mentalHealthProvidersFetching,
  } = mentalHealthProviders;

  const [drawerItem, setDrawerItem] = React.useState('');
  const [drawerData, setDrawerData] = React.useState<PatientMedication[] | Diagnosis[]>([]);
  const [drawerTabIndex, setDrawerTabIndex] = React.useState<number>(0);
  const closeDrawer = React.useCallback(() => {
    setDrawerItem('');
  }, [setDrawerItem]);

  const drawerChildProps = {
    drawerItem,
    closeDrawer,
    drawerMode: true,
  };

  const drawerTriggerProp = {
    drawerItem,
    closeDrawer,
    drawerMode: false,
  };

  const onShowMoreMedication = (data: PatientMedication[], tabIndex?: number) => {
    setDrawerItem(EDrawerItem.MEDICATION_HISTORY);
    setDrawerData(data);
    setDrawerTabIndex(tabIndex || 0);
  };

  const onShowMoreDiagnosis = (data: Diagnosis[], tabIndex?: number) => {
    setDrawerItem(EDrawerItem.DIAGNOSIS_HISTORY);
    setDrawerData(data);
    setDrawerTabIndex(tabIndex || 0);
  };

  const onShowMoreSymptoms = (tabIndex?: number) => {
    setDrawerItem(EDrawerItem.SYMPTOMS);
    setDrawerTabIndex(tabIndex || 0);
  };

  return (
    <>
      <ShowMoreDrawer drawerItem={drawerItem} closeDrawer={closeDrawer}>
        {drawerItem === EDrawerItem.SYMPTOMS ? (
          <SymptomsCard
            {...drawerChildProps}
            drawerTabIndex={drawerTabIndex}
            reportedSymptoms={currentSymptomsResponse?.patientSymptoms}
            reportedSymptomsHistory={symptomsResponse?.patientSymptoms}
          />
        ) : null}

        {drawerItem === EDrawerItem.LABS ? (
          <LabCard {...drawerChildProps} labReports={labsResponse?.labReports} />
        ) : null}

        {drawerItem === EDrawerItem.MEDICATION_HISTORY ? (
          <MedicationCard
            isCurrentRegime={false}
            showTabs
            drawerTabIndex={drawerTabIndex}
            {...drawerChildProps}
            medication={drawerData as PatientMedication[]}
          />
        ) : null}

        {drawerItem === EDrawerItem.DIAGNOSIS_HISTORY ? (
          <DiagnosisCard
            showTabs
            drawerTabIndex={drawerTabIndex}
            {...drawerChildProps}
            diagnosis={diagnosisResponse?.patientDiagnosis}
          />
        ) : null}
        {drawerItem === EDrawerItem.FAMILY_HISTORY ? (
          <FamilyHistoryCard
            {...drawerChildProps}
            familyHistory={familyHistoryResponse?.familyHistory}
          />
        ) : null}
        {drawerItem === EDrawerItem.PROVIDER_HISTORY ? (
          <ProvidersCard
            {...drawerChildProps}
            patientProviders={providerResponse?.patientProviders}
            primaryCareProviders={primaryCareProvidersResponse?.primaryCareProviders}
            mentalHealthProviders={mentalHealthProvidersResponse?.mentalHealthProviders}
          />
        ) : null}
        {drawerItem === EDrawerItem.HOSPITALIZATIONS ? (
          <HospitalizationsCard
            {...drawerChildProps}
            patientHospitalizations={hospitalizationsResponse?.patientHospitalizations}
          />
        ) : null}
        {drawerItem === EDrawerItem.ALLERGIES ? (
          <AllergiesCard {...drawerChildProps} allergies={allergiesResponse?.allergies} />
        ) : null}
      </ShowMoreDrawer>

      <SectionHeader title='Health record history' />
      <Grid container spacing={1}>
        {diagnosisFetching ? (
          <Spinner />
        ) : (
          <DiagnosisCard
            {...drawerTriggerProp}
            diagnosis={diagnosisResponse?.patientDiagnosis}
            onShowMoreDiagnosis={onShowMoreDiagnosis}
            showTabs
          />
        )}
        {medicationFetching ? (
          <Spinner />
        ) : (
          <MedicationCard
            {...drawerTriggerProp}
            isCurrentRegime={false}
            onShowMoreMedication={onShowMoreMedication}
            medication={medicationResponse?.patientMedications}
            showTabs
          />
        )}
        {familyHistoryFetching ? (
          <Spinner />
        ) : (
          <FamilyHistoryCard
            {...drawerTriggerProp}
            onShowMore={() => setDrawerItem(EDrawerItem.FAMILY_HISTORY)}
            familyHistory={familyHistoryResponse?.familyHistory}
          />
        )}
        {backgroundFetching || lifeStyleFetching ? (
          <Spinner />
        ) : (
          <PsychosocialCard
            lifeStyle={lifeStyleResponse?.patientLifeStyles}
            background={backgroundResponse?.patientBackgrounds}
          />
        )}
        {primaryCareProvidersFetching
        || providerFetching
        || mentalHealthProvidersFetching ? (
          <Spinner />
          ) : (
            <ProvidersCard
              {...drawerTriggerProp}
              patientProviders={providerResponse?.patientProviders}
              primaryCareProviders={primaryCareProvidersResponse?.primaryCareProviders}
              mentalHealthProviders={mentalHealthProvidersResponse?.mentalHealthProviders}
              onShowMore={() => setDrawerItem(EDrawerItem.PROVIDER_HISTORY)}
            />
          )}
        {currentSymptomsFetching || symptomsFetching ? (
          <Spinner />
        ) : (
          <SymptomsCard
            {...drawerTriggerProp}
            onShowMoreSymptoms={onShowMoreSymptoms}
            reportedSymptoms={currentSymptomsResponse?.patientSymptoms}
            reportedSymptomsHistory={symptomsResponse?.patientSymptoms}
          />
        )}
        {allergiesFetching ? (
          <Spinner />
        ) : (
          <AllergiesCard
            {...drawerTriggerProp}
            onShowMore={() => setDrawerItem(EDrawerItem.ALLERGIES)}
            allergies={allergiesResponse?.allergies}
          />
        )}
        {hospitalizationsFetching ? (
          <Spinner />
        ) : (
          <HospitalizationsCard
            {...drawerTriggerProp}
            onShowMore={() => setDrawerItem(EDrawerItem.HOSPITALIZATIONS)}
            patientHospitalizations={hospitalizationsResponse?.patientHospitalizations}
          />
        )}
        {labsFetching ? (
          <Spinner />
        ) : (
          <LabCard
            {...drawerTriggerProp}
            onShowMore={() => setDrawerItem(EDrawerItem.LABS)}
            labReports={labsResponse?.labReports}
          />
        )}
        {lifeStyleFetching ? (
          <Spinner />
        ) : (
          <SubstanceCard lifeStyle={lifeStyleResponse?.patientLifeStyles} />
        )}
      </Grid>
    </>
  );
}
