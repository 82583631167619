import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import Footer from 'components/Layouts/Footer';
import * as React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { LogoUrl } from 'utils/constants';
import { auth } from 'utils/spaUrls';


interface IProps {
  children: React.ReactNode,
}

export default function BrandingBorder({ children }: IProps) {
  const theme = useTheme();
  const location = useLocation();
  const isLogin = location.pathname === auth.login;
  return (
    <Grid container direction='column' spacing={8}>
      <Grid item xs={1}>
        <Grid container justifyContent='space-between' spacing={2}>
          <Grid item xs={1} mt={2}>
            <img height={theme.custom.logoHeight} width={theme.custom.logoWidth} src={LogoUrl.lg} alt='' />
          </Grid>
          {!isLogin ? (
            <Grid item xs={3} md={1}>
              <Link component={RouterLink} to={auth.login} underline='hover'>SIGN IN</Link>
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={12}><Divider /></Grid>
      </Grid>
      <Grid item xs={6}>{children}</Grid>
      <Grid item xs={3}><Footer /></Grid>
    </Grid>
  );
}
