import { SvgIcon, SvgIconProps } from '@mui/material';
import * as React from 'react';
import Theme from 'theme';

function EditWaypoint(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' width={24} height={25} fill='none'>
        <path
          fill={Theme.custom.darkTextPrimary}
          d='M13.95 13.781H9v-4.95l5.61-5.61a8.17 8.17 0 0 0-2.61-.44c-4.2 0-8 3.22-8 8.2 0 3.32 2.67 7.25 8 11.8 5.33-4.55 8-8.48 8-11.8 0-1.01-.16-1.94-.45-2.8l-5.6 5.6Z'
        />
        <path
          fill='#CAA360'
          d='M11 11.781h2.12l6.16-6.16-2.12-2.12L11 9.661v2.12ZM20.71 2.781l-.71-.71c-.2-.19-.45-.29-.71-.29-.13 0-.48.07-.71.29l-.72.72 2.12 2.12.72-.72c.4-.39.4-1.02.01-1.41Z'
        />
      </svg>
    </SvgIcon>
  );
}

export default EditWaypoint;
