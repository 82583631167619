import { SmokingRooms } from '@mui/icons-material';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import Groups from '@mui/icons-material/Groups';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import Medication from '@mui/icons-material/Medication';
import Psychology from '@mui/icons-material/Psychology';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { ReactComponent as MedicationIcon } from 'assets/MedicationIcon.svg';
import { ReactComponent as HospitalizationIcon } from 'assets/Hospitalizations.svg';
import { ReactComponent as AllergiesIcon } from 'assets/Allergies.svg';
import { ReactComponent as LabsIcon } from 'assets/Labs.svg';
import { ReactComponent as SymptomsIcon } from 'assets/Symptoms.svg';
import { ReactComponent as Diagnoses } from 'assets/DiagnosesIcon.svg';
import { ReactComponent as FamilyHistory } from 'assets/FamilyHistory.svg';
import { ReactComponent as ProviderIcon } from 'assets/ProviderIcon.svg';
import { ReactComponent as SubstanceIcon } from 'assets/SubstanceIcon.svg';
import { ReactComponent as PsychologicalIcon } from 'assets/PsychologicalIcon.svg';


type Category = {
  displayName: string;
  icon: OverridableComponent<SvgIconTypeMap> & { muiName: string; };
  Icon:React.FunctionComponent<React.SVGProps<SVGSVGElement>>

};

export default class HealthRecordCategories {
  static readonly medication = new HealthRecordCategories('medication', {
    displayName: 'Medication',
    icon: Medication,
    Icon: MedicationIcon,
  });

  static readonly diagnosis = new HealthRecordCategories('diagnosis', {
    displayName: 'Diagnosis',
    icon: Psychology,
    Icon: Diagnoses,
  });

  static readonly careTeam = new HealthRecordCategories('careTeam', {
    displayName: 'Care Team',
    icon: Groups,
    Icon: MedicationIcon,
  });

  static readonly familyHistory = new HealthRecordCategories('familyHistory', {
    displayName: 'Family History',
    icon: FamilyRestroomIcon,
    Icon: FamilyHistory,

  });

  static readonly psychosocial = new HealthRecordCategories('psychosocial', {
    displayName: 'Psychosocial',
    icon: SummarizeIcon,
    Icon: PsychologicalIcon,
  });

  static readonly providers = new HealthRecordCategories('providers', {
    displayName: 'Providers',
    icon: LocalHospitalIcon,
    Icon: ProviderIcon,
  });

  static readonly reportedCondition = new HealthRecordCategories('reportedCondition', {
    displayName: 'Reported Condition',
    icon: LocalHospitalIcon,
    Icon: HospitalizationIcon,
  });

  static readonly hospitalizations = new HealthRecordCategories('hospitalizations', {
    displayName: 'Hospitalizations',
    icon: LocalHospitalIcon,
    Icon: HospitalizationIcon,
  });

  static readonly allergies = new HealthRecordCategories('allergies', {
    displayName: 'Allergies',
    icon: LocalHospitalIcon,
    Icon: AllergiesIcon,
  });

  static readonly labs = new HealthRecordCategories('labs', {
    displayName: 'Labs',
    icon: LocalHospitalIcon,
    Icon: LabsIcon,
  });

  static readonly symptoms = new HealthRecordCategories('symptoms', {
    displayName: 'Symptoms',
    icon: LocalHospitalIcon,
    Icon: SymptomsIcon,
  });

  static readonly substance = new HealthRecordCategories('substance', {
    displayName: 'Substance',
    icon: SmokingRooms,
    Icon: SubstanceIcon,
  });

  private constructor(private readonly key: string, public readonly value: Category) {
  }

  toString() {
    return this.key;
  }
}
