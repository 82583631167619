import { Box, Card, CardContent, Grid, Typography, CardHeader } from '@mui/material';
import NoData from 'pages/Dashboard/components/NoData';
import UserAddedToolTip from 'pages/Dashboard/components/UserAddedToolTip';
import HealthRecordCategories from 'pages/Dashboard/HealthRecordCategories';
import { ShowMoreButton, ShowMoreDrawerChildProps, ShowMoreDrawerTitle, sliceForShowMore } from 'pages/Dashboard/ShowMoreDrawer';
import { PatientHospitalization } from 'pages/Dashboard/types/hospitalizations.types';
import * as React from 'react';
import theme from 'theme';
import { googleDateToDateString } from 'utils/dateUtils';


export interface HospitalizationsCardProps extends ShowMoreDrawerChildProps {
  patientHospitalizations?: PatientHospitalization[];
  isNewUi?:boolean
}

export default function HospitalizationsCard({
  closeDrawer,
  drawerItem,
  drawerMode = false,
  patientHospitalizations = [],
  onShowMore,
  isNewUi,
}: HospitalizationsCardProps): JSX.Element | null {
  if (patientHospitalizations.length === 0 && isNewUi) {
    return null;
  }
  const data = sliceForShowMore(patientHospitalizations, !drawerMode);
  const { Icon } = HealthRecordCategories.hospitalizations.value;
  return (
    <Grid item xs={12} md={!drawerMode ? 4 : undefined} key='hospitalizations'>
      <Card sx={{ height: '100%' }}>
        {!drawerMode && (
        <CardHeader
          avatar={<Icon />}
          title='Hospitalizations'
          titleTypographyProps={{ variant: 'h5', fontSize: 24, fontWeight: 400 }}
        />
        )}
        {drawerMode && <ShowMoreDrawerTitle closeDrawer={closeDrawer} drawerItem={drawerItem} />}

        <CardContent>
          {patientHospitalizations.length ? data.map((hospitalization) => (
            <Box display='flex' flexDirection='row' justifyContent='space-between'>
              <Box display='flex' flexDirection='column'>
                <Box key={`hospitalization-${hospitalization.id}`} mb={2}>
                  <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
                    {`${googleDateToDateString(hospitalization.startDate)}
                        -
                  ${googleDateToDateString(hospitalization.endDate)}`}
                  </Typography>

                  {hospitalization.locations.map((location) => (
                    <Typography>{`${location.name} `}</Typography>
                  ))}
                  {hospitalization.diagnosis.map((patientDiagnosis) => (
                    <Typography
                      key={patientDiagnosis.id}
                      variant='subtitle1'
                    >
                      {patientDiagnosis.name}
                    </Typography>
                  ))}
                </Box>
              </Box>
              <UserAddedToolTip source={hospitalization?.source} />
            </Box>
          )) : <NoData />}
          <ShowMoreButton
            isDrawer={drawerMode}
            onShowMore={onShowMore as VoidFunction}
            items={patientHospitalizations}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}
