import { Box, Tooltip } from '@mui/material';
import { SourceTypeMap } from 'pages/Dashboard/types';
import React from 'react';
import { ReactComponent as User } from 'assets/UserIcon.svg';
import Theme from 'theme';

export default function UserAddedToolTip({ source }: {source: string}) {
  return source === SourceTypeMap.SOURCE_TYPE_PATIENT_REPORTED ? (
    <Tooltip title='This item was added by patient' placement='top'>
      <Box
        sx={{
          width: 20,
          height: 20,
          borderRadius: 20,
          bgcolor: Theme.custom.colors.backgroundColorSecondary,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <User />
      </Box>
    </Tooltip>
  ) : null;
}

