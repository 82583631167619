import Grid from '@mui/material/Grid';
import AllergiesCard from 'pages/Dashboard/components/AllergiesCard';
import FamilyHistoryCard from 'pages/Dashboard/components/FamilyHistoryCard';
import HospitalizationsCard from 'pages/Dashboard/components/HospitalizationsCard';
import LabCard from 'pages/Dashboard/components/LabCard';
import ProvidersCard from 'pages/Dashboard/components/ProvidersCard';
import PsychosocialCard from 'pages/Dashboard/components/PsychosocialCard';
import ShowMoreDrawer from 'pages/Dashboard/ShowMoreDrawer';
import * as React from 'react';
import Spinner from 'components/Common/Spinner';
import { MedicationAPIResponse, PatientMedication } from 'pages/Dashboard/types/medication.types';
import { Diagnosis, DiagnosisAPIResponse } from 'pages/Dashboard/types/diagnosis.types';
import SubstanceCard from 'pages/Dashboard/components/SubstanceCard';
import SymptomsCard from 'pages/Dashboard/components/SymptomsCard';
import { UseQueryResult } from 'react-query';
import { FamilyHistoryAPIResponse } from 'pages/Dashboard/types/familyHistory.types';
import { PatientSymptomsAPIResponse } from 'pages/Dashboard/types/symptoms.types';
import { AllergiesAPIResponse } from 'pages/Dashboard/types/allergies.types';
import { HospitalizationAPIResponse } from 'pages/Dashboard/types/hospitalizations.types';
import {
  ListMentalHealthProvidersAPIResponse,
  ListPrimaryCareProvidersAPIResponse,
  ProvidersAPIResponse,
} from 'pages/Dashboard/types/providers.types';
import { PatientLifeStyleAPIResponse } from 'pages/Dashboard/types/lifestyle.types';
import { PatientBackgroundAPIResponse } from 'pages/Dashboard/types/background.types';
import { LabAPIResponse } from 'pages/Dashboard/types/lab.types';
import MedicationCardNew from 'pages/Dashboard/components/MedicationCardNew';
import { Box, Typography } from '@mui/material';
import DiagnosisCardNew from 'pages/Dashboard/components/DiagnosisCardNew';
import HealthRecordCategories from 'pages/Dashboard/HealthRecordCategories';
import Theme from 'theme';

enum EDrawerItem {
  SYMPTOMS = 'Symptoms',
  LABS = 'Labs',
  MEDICATION_HISTORY = 'Medication history',
  DIAGNOSIS_HISTORY = 'Diagnosis history',
  FAMILY_HISTORY = 'Family History',
  PROVIDER_HISTORY = 'Provider history',
  HOSPITALIZATIONS = 'Hospitalizations',
  ALLERGIES = 'Allergies',
}

interface IHealthRecordProps {
  diagnosis: UseQueryResult<DiagnosisAPIResponse>;
  medications: UseQueryResult<MedicationAPIResponse>;
  familyHistory: UseQueryResult<FamilyHistoryAPIResponse>;
  symptoms: UseQueryResult<PatientSymptomsAPIResponse>;
  currentSymptoms: UseQueryResult<PatientSymptomsAPIResponse>;
  allergies: UseQueryResult<AllergiesAPIResponse>;
  hospitalizations: UseQueryResult<HospitalizationAPIResponse>;
  providers: UseQueryResult<ProvidersAPIResponse>;
  primaryCareProviders: UseQueryResult<ListPrimaryCareProvidersAPIResponse>;
  mentalHealthProviders: UseQueryResult<ListMentalHealthProvidersAPIResponse>;
  lifeStyle: UseQueryResult<PatientLifeStyleAPIResponse>;
  backgrounds: UseQueryResult<PatientBackgroundAPIResponse>;
  labs: UseQueryResult<LabAPIResponse>;
}
function isDataEmpty({
  diagnosisResponse,
  medicationResponse,
  currentSymptomsResponse,
  symptomsResponse,
  familyHistoryResponse,
  allergiesResponse,
  hospitalizationsResponse,
  providerResponse,
  labsResponse,
  lifeStyleResponse,
  backgroundResponse,
  primaryCareProvidersResponse,
  mentalHealthProvidersResponse,
}: {
  diagnosisResponse?: DiagnosisAPIResponse;
  medicationResponse?: MedicationAPIResponse;
  currentSymptomsResponse?: PatientSymptomsAPIResponse;
  symptomsResponse?: PatientSymptomsAPIResponse;
  familyHistoryResponse?: FamilyHistoryAPIResponse;
  allergiesResponse?: AllergiesAPIResponse;
  hospitalizationsResponse?: HospitalizationAPIResponse;
  providerResponse?: ProvidersAPIResponse;
  labsResponse?: LabAPIResponse;
  lifeStyleResponse?: PatientLifeStyleAPIResponse;
  backgroundResponse?: PatientBackgroundAPIResponse;
  primaryCareProvidersResponse?: ListPrimaryCareProvidersAPIResponse;
  mentalHealthProvidersResponse?: ListMentalHealthProvidersAPIResponse;
}): boolean {
  return (
    !diagnosisResponse?.patientDiagnosis?.length
    && !medicationResponse?.patientMedications?.length
    && !currentSymptomsResponse?.patientSymptoms?.length
    && !symptomsResponse?.patientSymptoms?.length
    && !familyHistoryResponse?.familyHistory?.length
    && !allergiesResponse?.allergies?.length
    && !hospitalizationsResponse?.patientHospitalizations?.length
    && !providerResponse?.patientProviders?.length
    && !labsResponse?.labReports?.length
    && !lifeStyleResponse?.patientLifeStyles?.length
    && !backgroundResponse?.patientBackgrounds?.length
    && !primaryCareProvidersResponse?.primaryCareProviders?.length
    && !mentalHealthProvidersResponse?.mentalHealthProviders?.length
  );
}

function getDataPresenceStatus({
  diagnosisResponse,
  medicationResponse,
  currentSymptomsResponse,
  symptomsResponse,
  familyHistoryResponse,
  allergiesResponse,
  hospitalizationsResponse,
  providerResponse,
  labsResponse,
  lifeStyleResponse,
  backgroundResponse,
  primaryCareProvidersResponse,
  mentalHealthProvidersResponse,
}: {
  diagnosisResponse?: DiagnosisAPIResponse;
  medicationResponse?: MedicationAPIResponse;
  currentSymptomsResponse?: PatientSymptomsAPIResponse;
  symptomsResponse?: PatientSymptomsAPIResponse;
  familyHistoryResponse?: FamilyHistoryAPIResponse;
  allergiesResponse?: AllergiesAPIResponse;
  hospitalizationsResponse?: HospitalizationAPIResponse;
  providerResponse?: ProvidersAPIResponse;
  labsResponse?: LabAPIResponse;
  lifeStyleResponse?: PatientLifeStyleAPIResponse;
  backgroundResponse?: PatientBackgroundAPIResponse;
  primaryCareProvidersResponse?: ListPrimaryCareProvidersAPIResponse;
  mentalHealthProvidersResponse?: ListMentalHealthProvidersAPIResponse;
}) {
  const presenceStatus = {
    [HealthRecordCategories.diagnosis.value.displayName]:
      !!diagnosisResponse?.patientDiagnosis?.length,
    [HealthRecordCategories.medication.value.displayName]:
      !!medicationResponse?.patientMedications?.length,
    [HealthRecordCategories.symptoms.value.displayName]:
      !!currentSymptomsResponse?.patientSymptoms?.length
      || !!symptomsResponse?.patientSymptoms?.length,
    [HealthRecordCategories.familyHistory.value.displayName]:
      !!familyHistoryResponse?.familyHistory?.length,
    [HealthRecordCategories.allergies.value.displayName]: !!allergiesResponse?.allergies?.length,
    [HealthRecordCategories.hospitalizations.value.displayName]:
      !!hospitalizationsResponse?.patientHospitalizations?.length,
    [HealthRecordCategories.providers.value.displayName]:
      !!providerResponse?.patientProviders?.length
      || !!primaryCareProvidersResponse?.primaryCareProviders?.length
      || !!mentalHealthProvidersResponse?.mentalHealthProviders?.length,
    [HealthRecordCategories.labs.value.displayName]: !!labsResponse?.labReports?.length,
    [HealthRecordCategories.psychosocial.value.displayName]:
      !!lifeStyleResponse?.patientLifeStyles?.length
      || !!backgroundResponse?.patientBackgrounds?.length,
  };

  // Calculate entireData based on individual statuses

  return {
    ...presenceStatus,
  };
}

export default function HealthRecordNew({
  diagnosis,
  medications,
  familyHistory,
  symptoms,
  currentSymptoms,
  allergies,
  hospitalizations,
  providers,
  primaryCareProviders,
  mentalHealthProviders,
  lifeStyle,
  backgrounds,
  labs,
}: IHealthRecordProps) {
  const { data: diagnosisResponse, isFetching: diagnosisFetching } = diagnosis;
  const { data: medicationResponse, isFetching: medicationFetching } = medications;
  const { data: currentSymptomsResponse, isFetching: currentSymptomsFetching } = currentSymptoms;
  const { data: symptomsResponse, isFetching: symptomsFetching } = symptoms;
  const { data: familyHistoryResponse, isFetching: familyHistoryFetching } = familyHistory;
  const { data: allergiesResponse, isFetching: allergiesFetching } = allergies;
  const { data: hospitalizationsResponse, isFetching: hospitalizationsFetching } = hospitalizations;
  const { data: providerResponse, isFetching: providerFetching } = providers;
  const { data: labsResponse, isFetching: labsFetching } = labs;
  const { data: lifeStyleResponse, isFetching: lifeStyleFetching } = lifeStyle;
  const { data: backgroundResponse, isFetching: backgroundFetching } = backgrounds;
  const {
    data: primaryCareProvidersResponse,
    isFetching: primaryCareProvidersFetching,
  } = primaryCareProviders;
  const {
    data: mentalHealthProvidersResponse,
    isFetching: mentalHealthProvidersFetching,
  } = mentalHealthProviders;

  const [drawerItem, setDrawerItem] = React.useState('');
  const [drawerData, setDrawerData] = React.useState<PatientMedication[] | Diagnosis[]>([]);
  const [drawerTabIndex, setDrawerTabIndex] = React.useState<number>(0);
  const closeDrawer = React.useCallback(() => {
    setDrawerItem('');
  }, [setDrawerItem]);

  const drawerChildProps = {
    drawerItem,
    closeDrawer,
    drawerMode: true,
  };

  const drawerTriggerProp = {
    drawerItem,
    closeDrawer,
    drawerMode: false,
  };

  const onShowMoreMedication = (data: PatientMedication[], tabIndex?: number) => {
    setDrawerItem(EDrawerItem.MEDICATION_HISTORY);
    setDrawerData(data);
    setDrawerTabIndex(tabIndex || 0);
  };

  const onShowMoreDiagnosis = (data: Diagnosis[], tabIndex?: number) => {
    setDrawerItem(EDrawerItem.DIAGNOSIS_HISTORY);
    setDrawerData(data);
    setDrawerTabIndex(tabIndex || 0);
  };

  const onShowMoreSymptoms = (tabIndex?: number) => {
    setDrawerItem(EDrawerItem.SYMPTOMS);
    setDrawerTabIndex(tabIndex || 0);
  };
  const noDataAvailable = isDataEmpty({
    diagnosisResponse,
    medicationResponse,
    currentSymptomsResponse,
    symptomsResponse,
    familyHistoryResponse,
    allergiesResponse,
    hospitalizationsResponse,
    providerResponse,
    labsResponse,
    lifeStyleResponse,
    backgroundResponse,
    primaryCareProvidersResponse,
    mentalHealthProvidersResponse,
  });
  const getDataPresence = getDataPresenceStatus({
    diagnosisResponse,
    medicationResponse,
    currentSymptomsResponse,
    symptomsResponse,
    familyHistoryResponse,
    allergiesResponse,
    hospitalizationsResponse,
    providerResponse,
    labsResponse,
    lifeStyleResponse,
    backgroundResponse,
    primaryCareProvidersResponse,
    mentalHealthProvidersResponse,
  });
  const absentDataList = Object.keys(getDataPresence).filter(
    (key) => !getDataPresence[key as keyof typeof getDataPresence],
  );
  return (
    <>
      <ShowMoreDrawer drawerItem={drawerItem} closeDrawer={closeDrawer}>
        {drawerItem === EDrawerItem.SYMPTOMS ? (
          <SymptomsCard
            {...drawerChildProps}
            drawerTabIndex={drawerTabIndex}
            reportedSymptoms={currentSymptomsResponse?.patientSymptoms}
            reportedSymptomsHistory={symptomsResponse?.patientSymptoms}
            isNewUi
          />
        ) : null}

        {drawerItem === EDrawerItem.LABS ? (
          <LabCard {...drawerChildProps} labReports={labsResponse?.labReports} isNewUi />
        ) : null}

        {drawerItem === EDrawerItem.MEDICATION_HISTORY ? (
          <MedicationCardNew
            isCurrentRegime={false}
            showTabs
            drawerTabIndex={drawerTabIndex}
            {...drawerChildProps}
            medication={drawerData as PatientMedication[]}
          />
        ) : null}

        {drawerItem === EDrawerItem.DIAGNOSIS_HISTORY ? (
          <DiagnosisCardNew
            showTabs
            drawerTabIndex={drawerTabIndex}
            {...drawerChildProps}
            diagnosis={diagnosisResponse?.patientDiagnosis ?? []}
          />
        ) : null}
        {drawerItem === EDrawerItem.FAMILY_HISTORY ? (
          <FamilyHistoryCard
            {...drawerChildProps}
            familyHistory={familyHistoryResponse?.familyHistory}
            isNewUi
          />
        ) : null}
        {drawerItem === EDrawerItem.PROVIDER_HISTORY ? (
          <ProvidersCard
            {...drawerChildProps}
            patientProviders={providerResponse?.patientProviders}
            primaryCareProviders={primaryCareProvidersResponse?.primaryCareProviders}
            mentalHealthProviders={mentalHealthProvidersResponse?.mentalHealthProviders}
            isNewUi
          />
        ) : null}
        {drawerItem === EDrawerItem.HOSPITALIZATIONS ? (
          <HospitalizationsCard
            {...drawerChildProps}
            patientHospitalizations={hospitalizationsResponse?.patientHospitalizations}
            isNewUi
          />
        ) : null}
        {drawerItem === EDrawerItem.ALLERGIES ? (
          <AllergiesCard
            {...drawerChildProps}
            allergies={allergiesResponse?.allergies}
            isNewUi
          />
        ) : null}
      </ShowMoreDrawer>

      {noDataAvailable ? (
        <Typography color={Theme.custom.colors.lightTextSecondary} mt={-2}>
          No treatment regimen & history data exists at this time.
        </Typography>
      ) : (
        <Grid container spacing={1}>
          {medicationFetching ? (
            <Spinner />
          ) : (
            <MedicationCardNew
              {...drawerTriggerProp}
              isCurrentRegime={false}
              onShowMoreMedication={onShowMoreMedication}
              medication={medicationResponse?.patientMedications}
              showTabs
            />
          )}
          {diagnosisFetching ? (
            <Spinner />
          ) : (
            <DiagnosisCardNew
              {...drawerTriggerProp}
              diagnosis={diagnosisResponse?.patientDiagnosis ?? []}
              onShowMoreDiagnosis={onShowMoreDiagnosis}
              showTabs
            />
          )}
          {primaryCareProvidersFetching || providerFetching || mentalHealthProvidersFetching ? (
            <Spinner />
          ) : (
            <ProvidersCard
              {...drawerTriggerProp}
              patientProviders={providerResponse?.patientProviders}
              primaryCareProviders={primaryCareProvidersResponse?.primaryCareProviders}
              mentalHealthProviders={mentalHealthProvidersResponse?.mentalHealthProviders}
              onShowMore={() => setDrawerItem(EDrawerItem.PROVIDER_HISTORY)}
              isNewUi
            />
          )}
          {familyHistoryFetching ? (
            <Spinner />
          ) : (
            <FamilyHistoryCard
              {...drawerTriggerProp}
              onShowMore={() => setDrawerItem(EDrawerItem.FAMILY_HISTORY)}
              familyHistory={familyHistoryResponse?.familyHistory}
              isNewUi
            />
          )}
          {backgroundFetching || lifeStyleFetching ? (
            <Spinner />
          ) : (
            <PsychosocialCard
              lifeStyle={lifeStyleResponse?.patientLifeStyles}
              background={backgroundResponse?.patientBackgrounds}
              isNewUi
            />
          )}

          {currentSymptomsFetching || symptomsFetching ? (
            <Spinner />
          ) : (
            <SymptomsCard
              {...drawerTriggerProp}
              onShowMoreSymptoms={onShowMoreSymptoms}
              reportedSymptoms={currentSymptomsResponse?.patientSymptoms}
              reportedSymptomsHistory={symptomsResponse?.patientSymptoms}
              isNewUi
            />
          )}
          {allergiesFetching ? (
            <Spinner />
          ) : (
            <AllergiesCard
              {...drawerTriggerProp}
              onShowMore={() => setDrawerItem(EDrawerItem.ALLERGIES)}
              allergies={allergiesResponse?.allergies}
              isNewUi
            />
          )}
          {hospitalizationsFetching ? (
            <Spinner />
          ) : (
            <HospitalizationsCard
              {...drawerTriggerProp}
              onShowMore={() => setDrawerItem(EDrawerItem.HOSPITALIZATIONS)}
              patientHospitalizations={hospitalizationsResponse?.patientHospitalizations}
              isNewUi
            />
          )}
          {labsFetching ? (
            <Spinner />
          ) : (
            <LabCard
              {...drawerTriggerProp}
              onShowMore={() => setDrawerItem(EDrawerItem.LABS)}
              labReports={labsResponse?.labReports}
              isNewUi
            />
          )}
          {lifeStyleFetching ? (
            <Spinner />
          ) : (
            <SubstanceCard
              lifeStyle={lifeStyleResponse?.patientLifeStyles}
              isNewUi
            />
          )}
        </Grid>
      )}
      {absentDataList?.length > 0 ? (
        <>
          <Typography
            fontSize={20}
            fontWeight={500}
            color={Theme.custom.colors.lightTextPrimary}
            mb={1}
            mt={2}
          >
            These categories may be populated by the patient via the Headlamp mobile app:
          </Typography>
          {absentDataList.map((category, index) => {
            const Icon = HealthRecordCategories[
              category === 'Family History'
                ? 'familyHistory'
                : (category?.toLowerCase() as keyof typeof HealthRecordCategories)
            ]?.value?.Icon;
            return (
              <Box display='flex' flexDirection='row' gap={2} mt={1} alignItems='center' key={category}>
                {Icon && <Icon width={18} height={18} />}
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color={Theme.custom.colors.lightTextPrimary}
                  key={category}
                >
                  {category}
                </Typography>
              </Box>
            );
          })}
        </>
      ) : null}
    </>
  );
}
