import { PatientGender, SourceType } from 'pages/Dashboard/types';
import { GoogleDate } from 'utils/dateUtils';

export enum DiagnosisCategory {
    DIAGNOSIS_CATEGORY_UNSPECIFIED = 0,
    DIAGNOSIS_CATEGORY_PSYCH = 1,
    DIAGNOSIS_CATEGORY_OTHER = 2,
}

export enum DisorderLethal {
    DISORDER_UNSPECIFIED = 0,
    DISORDER_LETHAL = 1,
    DISORDER_NON_LETHAL = 2,
}

export enum RelativeDeceased {
    RELATIVE_DECEASED_UNSPECIFIED = 0,
    RELATIVE_DECEASED_DECEASED = 1,
    RELATIVE_DECEASED_NOT_DECEASED = 2,
}

export interface Relative {
    id: string;
    name: string;
    gender: PatientGender;
    dateOfBirth: GoogleDate | null;
    deceased: RelativeDeceased;
    deceasedDate: GoogleDate | null;
    source: SourceType;
}

export interface Disorder {
    id: string
    name: string;
    date: GoogleDate | null;
    lethal: DisorderLethal;
    code: string;
    age: number;
    text: string;
    source: keyof typeof SourceType;
}


export interface FamilyHistory {
    relation: string;
    disorders: Disorder[];
    relationCode: string;
    relative: Relative;
    relationText: string;
}


export interface FamilyHistoryAPIResponse {
    familyHistory: FamilyHistory[]
}
