import { Typography } from '@mui/material';
import { ProviderFullDetails } from 'pages/Dashboard/types/diagnosis.types';
import * as React from 'react';
import Theme from 'theme';


interface DoctorTextProps{
    doctor: ProviderFullDetails | null,
}


export default function DoctorDesignationText({ doctor }: DoctorTextProps) {
  if (!doctor || !doctor.firstName) return null;

  return (
    <Typography fontSize={16} display='inline-block'>
      {`${doctor.namePrefix} ${doctor.firstName} ${doctor.middleName} ${doctor.lastName} ${doctor.nameSuffix}`}
      <Typography fontSize={14} color={Theme.custom.colors.lightTextSecondary} ml={1} display='inline-block'>
        {`    (${doctor.taxonomyGroup})`}
      </Typography>
    </Typography>
  );
}
