import { DiagnosisAPIResponse, DiagnosisHistoryAPIResponse } from 'pages/Dashboard/types/diagnosis.types';
import { QueryFunctionContext } from 'react-query';
import { diagnosisUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

export const fetchDiagnosisList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<DiagnosisAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return (
    method(diagnosisUrls.listDiagnosis.apiUrls(
      providerId as string,
      patientId as string,
    ).list)
  );
};

export const fetchDiagnosisListCombined = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<DiagnosisAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return (
    method(diagnosisUrls.listDiagnosis.apiUrls(
      providerId as string,
      patientId as string,
      'true' as string,
    ).list)
  );
};

export const fetchCurrentDiagnosisList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<DiagnosisAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return (
    method(diagnosisUrls.listDiagnosis.apiUrls(
      providerId as string,
      patientId as string,
    ).listCurrent)
  );
};

export const fetchDiagnosisHistory = (method: HttpType['get'], identifierType: 'meta' | 'pmi' = 'meta') => function async({ queryKey }: QueryFunctionContext): Promise<DiagnosisHistoryAPIResponse> {
  const [, patientId, diagnosisId, providerId] = queryKey;
  return (
    method(diagnosisUrls.history.apiUrls(
      diagnosisId as string,
      providerId as string,
      patientId as string,
    ).list)
  );
};


