import { Box, Typography } from '@mui/material';
import { Refills } from 'pages/Dashboard/types/medication.types';
import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import Theme from 'theme';

type TimeLineChartProps = {
  refillsWithDate: Refills[];
  unknownDateRefillsCount: number;
  medicationName: string;
};

const formatXAxis = (tickItem: number) => {
  const date = new Date(tickItem * 1000);
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
};

const calculateWidth = (dataSize: number): number => {
  if (dataSize <= 10) return 500;
  if (dataSize <= 20) return dataSize * 40;
  return dataSize * 30;
};

function CustomToolTip({
  active,
  payload,
}: {
  active?: number;
  payload?: {
    payload: {
      readableStartDate?: string,
      dosageString?:string,
      providerName?:string } }[];
}) {
  const data = payload?.[0]?.payload;
  if (active && data) {
    return (
      <Box
        sx={{
          p: '12px',
          backgroundColor: Theme.custom.tooltipBG,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {data?.readableStartDate && (
          <Typography fontWeight={700} fontSize='16px'>
            {data?.readableStartDate}
          </Typography>
        )}
        <Typography>{`${data?.dosageString}`}</Typography>
        <Typography>{data?.providerName}</Typography>
      </Box>
    );
  }
  return null;
}

function RefillChartAlternate({
  refillsWithDate,
  unknownDateRefillsCount,
  medicationName,
}: TimeLineChartProps) {
  if (refillsWithDate.length <= 1) {
    return (
      <Typography ml={2} mt={2} color={Theme.custom.colors.lightTextSecondary}>
        Not enough data to show this chart
      </Typography>
    );
  }
  const sortedData = refillsWithDate.sort(
    (a, b) => (a.dispensedDate ?? 0) - (b.dispensedDate ?? 0),
  );
  // Add custom index to data
  const dataWithIndex = sortedData.map((refill, index) => ({
    ...refill,
    customIndex: index + 1, // Add a sequential index
  }));

  const chartWidth = calculateWidth(dataWithIndex.length);
  const getMinMaxValues = (data: Refills[]) => {
    const values = data.map((item: Refills) => (parseInt(item.dosage, 10)));
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    return [minValue, maxValue];
  };

  const [, yMax] = getMinMaxValues(dataWithIndex);
  const dosageUnitString = dataWithIndex?.[0]?.dosageUnit
    ? `Dosage (${dataWithIndex?.[0]?.dosageUnit})`
    : '';

  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <Box style={{ width: chartWidth, minWidth: '100%', paddingRight: 20 }}>
        <ResponsiveContainer width='100%' height={323} minWidth={860}>
          <LineChart data={dataWithIndex} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray='0 0' />
            <XAxis
              dataKey='customIndex'
              type='number'
              domain={['dataMin', 'dataMax']}
              tickFormatter={(tick) => {
                const dataPoint = dataWithIndex.find((item) => item.customIndex === tick);
                return dataPoint ? formatXAxis(dataPoint?.dispensedDate ?? 0) : '';
              }}
              padding={{ left: 0, right: 0 }}
              interval='preserveStartEnd'
              allowDataOverflow
            />
            <YAxis
              label={{
                value: dosageUnitString,
                angle: -90,
                position: 'insideLeft',
                dx: 10,
                dy: 10,
                style: { textAnchor: 'middle' },
              }}
              tickCount={5}
              tickFormatter={(tick) => (tick === 0 ? '' : tick)}
              domain={[0, Math.max(1, yMax)]}
              padding={{ top: 0, bottom: 0 }}
              interval='equidistantPreserveStart'
            />
            <Tooltip content={<CustomToolTip />} isAnimationActive={false} />
            <Line
              type='monotone'
              dataKey='dosage'
              stroke={Theme.custom.darkTextPrimary}
              strokeWidth={2}
              connectNulls
              dot={{ r: 4, fill: Theme.custom.darkTextPrimary, stroke: '#fff', strokeWidth: 2 }} // Customize dot style
              activeDot={{ r: 6 }}
              fill={Theme.custom.darkTextPrimary}
              animationDuration={500}
              scale='linear'
            />
          </LineChart>
        </ResponsiveContainer>
        {unknownDateRefillsCount ? (
          <Typography color={Theme.custom.colors.lightTextSecondary} fontSize={16} ml={2} mt={3}>
            Other
            {' '}
            {medicationName}
            {' '}
            records without dates:
            <Typography
              color={Theme.custom.colors.lightTextPrimary}
              display='inline-block'
              ml={1.5}
            >
              {`  ${unknownDateRefillsCount}`}
            </Typography>
          </Typography>
        ) : null}
      </Box>
    </div>
  );
}

export default RefillChartAlternate;
