import { Box, Typography } from '@mui/material';
import React from 'react';
import Theme from 'theme';

interface TabItem {
  label: string;
  value: number;
  isDisabled?: boolean;
}
interface TabsProps {
  tabItems: TabItem[];
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
}

export default function Tabs({ tabItems, selectedTab, setSelectedTab }: TabsProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        borderWidth: 1,
        border: '1px solid',
        borderColor: Theme.custom.darkTextPrimary,
        borderRadius: '4px',
        width: '100%',
        flexShrink: 0,
        flexWrap: 'wrap',
        alignItems: 'stretch',
      }}
    >
      {tabItems?.map((tabItem, index) => (
        <Box
          sx={{
            display: 'flex',
            paddingTop: '8px',
            paddingBottom: '8px',
            backgroundColor: index === selectedTab ? '#0288D11F' : 'transparent',
            border: '0px solid',
            borderColor: Theme.custom.darkTextPrimary,
            borderLeftWidth: index === 0 ? 0 : 1,
            cursor: 'pointer',
            flexGrow: 1,
            width: '50%',
            textAlign: 'center',
            flexShrink: 0,
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          key={tabItem.label}
          onClick={() => setSelectedTab(index)}
        >
          <Typography
            sx={{
              color: tabItem?.isDisabled
                ? Theme.custom.colors.lightTextSecondary
                : Theme.custom.colors.primaryMain,
              fontWeight: '500',
              whiteSpace: 'nowrap',
            }}
          >
            {tabItem.label}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
