import * as React from 'react';
import { Box, capitalize, Grid, Skeleton, Tooltip, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  Category,
  CategoryResultsResponse,
  CategoryName,
  CategoryResult,
  Scale,
} from 'pages/Dashboard/types/whoiam.types';
import ScaleResultSummary from 'pages/Dashboard/components/ScaleResultSummary';
import QualityOfLifeText from 'pages/Dashboard/components/QualityOfLifeText';
import CoreValuesText from 'pages/Dashboard/components/CoreValuesText';
import DiscInfoText from 'pages/Dashboard/components/DiscInfoText';
import LocusOfControlText from 'pages/Dashboard/components/LocusOfControlText';
import GenericTestText from 'pages/Dashboard/components/GenericTestText';
import Theme from 'theme';
import PatientScaleDetailsDialog from 'pages/ScalesManifest/PatientScaleDetailsDialog';
import { GoogleDate, googleDateToDateString } from 'utils/dateUtils';
import { TableData } from 'pages/ScalesManifest/types/scales-manifest.types';
import { useHttp } from 'hooks/use-fetch';
import { format } from 'date-fns';
import { CategoryTooltipText } from 'pages/Dashboard/utils/whoiamUtils';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { PatientObj } from 'pages/Dashboard/types/patient.types';


interface PatientRatingScalesProps {
  ratingScales: Category[];
  personalityCategories: Category[];
  ratingScalesResults: (CategoryResultsResponse | undefined)[];
  personalityProfileData: (CategoryResultsResponse | undefined)[];
  isRatingScalesDataPresent: boolean;
  isPersonalityProfileDataPresent: boolean;
  id: string;
  isLoading: boolean;
  patient: PatientObj
}

export default function PatientRatingScalesSummary({
  ratingScales,
  ratingScalesResults,
  id,
  isRatingScalesDataPresent,
  isPersonalityProfileDataPresent,
  personalityProfileData,
  personalityCategories,
  isLoading,
  patient,
}: PatientRatingScalesProps): JSX.Element {
  let patientId = useParams().id;
  const [categoryLookup, setCategoryLookup] = React.useState<{ [key: string]: Category }>({});
  React.useEffect(() => {
    if (!personalityCategories) {
      return;
    }
    const tempLookup = categoryLookup;
    personalityCategories.forEach((category) => {
      tempLookup[category.name] = category;
    });

    setCategoryLookup(tempLookup);
  }, [personalityCategories, categoryLookup]);
  const renderCategoryData = (category: string, result?: CategoryResult) => {
    switch (category) {
      case CategoryName.WIM_CATEGORY_NAME_QUALITY_OF_LIFE:
        return <QualityOfLifeText result={result} />;

      case CategoryName.WIM_CATEGORY_NAME_CORE_VALUES:
        return <CoreValuesText result={result} />;

      case CategoryName.WIM_CATEGORY_NAME_LOCUS_OF_CONTROL:
        return <LocusOfControlText result={result} />;

      case CategoryName.WIM_CATEGORY_NAME_DISC:
        return <DiscInfoText result={result} />;
      default:
        return <GenericTestText result={result} />;
    }
  };
  if (!patientId) {
    patientId = id;
  }
  const mainData = () => {
    if (isRatingScalesDataPresent) {
      return ratingScalesResults?.map?.((result, index) => (
        <ScaleResultSummary
          results={result?.results ?? []}
          name={ratingScales[index].abbreviation}
          setSelectedScale={setSelectedScale}
          key={result?.results?.[0]?.attemptId}
        />
      ));
    }
    else if (isPersonalityProfileDataPresent) {
      return (
        <Box sx={{ pl: 0, width: '100%' }}>
          {personalityProfileData?.map?.((result, index) => {
            if (result?.results?.length === 0) {
              return null;
            }
            const tooltipText = CategoryTooltipText[personalityCategories?.[index]?.name];
            return (
              <Box sx={{ width: '100%', mt: 2 }}>
                <Typography color={Theme.custom.colors.lightTextSecondary} display='flex' alignItems='center' mb={1}>
                  {`${personalityCategories?.[index]?.description} `}
                  {tooltipText && (
                    <Tooltip title={<Typography>{tooltipText}</Typography>}>
                      <HelpOutlineIcon fontSize='small' sx={{ color: Theme.custom.colors.lightTextSecondary, ml: 1 }} />
                    </Tooltip>
                  )}
                </Typography>
                {renderCategoryData(personalityCategories?.[index].name, result?.results?.[0])}
              </Box>
            );
          })}
        </Box>
      );
    }
    else {
      return (
        <Typography color={Theme.custom.colors.lightTextSecondary}>
          No data for this timeframe
        </Typography>
      );
    }
  };
  const [selectedScale, setSelectedScale] = React.useState({} as Scale);
  const { isBillingAdmin, superAdminId } = useHttp();
  if (isLoading) {
    return <Skeleton height='100px' width='80%' />;
  }
  return (
    <Grid container width='100%' spacing={3} display='flex' flexWrap='wrap' mt={3} ml={0}>
      {mainData()}
      <PatientScaleDetailsDialog
        selectedRow={
            {
              attemptId: selectedScale?.attemptId,
              categoryId: selectedScale?.categoryId,
              takenOn: selectedScale?.attemptedAt
                ? format(new Date(selectedScale?.attemptedAt), 'M/d/yyyy')
                : '',
              metaInfo: selectedScale.metaInfo,
              score: `${selectedScale?.score}/${selectedScale?.totalScore}`,
              scale: selectedScale?.scale,
              patientName: `${capitalize(patient?.name?.firstName ?? '')} ${capitalize(
                patient?.name?.lastName ?? '',
              )}`,
              patientDOB: googleDateToDateString(patient?.dateOfBirth as GoogleDate),
              providerName: `${capitalize(patient?.provider?.firstName ?? '')} ${capitalize(
                patient?.provider?.lastName ?? '',
              )}`,
            } as unknown as TableData
          }
        setSelectedRow={() => setSelectedScale({} as Scale)}
        showNotes={!(isBillingAdmin || !!superAdminId)}
      />
    </Grid>
  );
}
