import { Box, BoxProps, ButtonBase, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DoctorText from 'pages/Dashboard/components/DoctorText';
import { TabLabels } from 'pages/Dashboard/components/MedicationCard';
import NoData from 'pages/Dashboard/components/NoData';
import HealthRecordCategories from 'pages/Dashboard/HealthRecordCategories';
import {
  ShowMoreButton,
  ShowMoreDrawerChildProps,
  ShowMoreDrawerTitle,
  sliceForShowMore,
} from 'pages/Dashboard/ShowMoreDrawer';
import { Diagnosis, DiagnosisCategory } from 'pages/Dashboard/types/diagnosis.types';
import * as React from 'react';
import { googleDateToDateString } from 'utils/dateUtils';
import {
  findPreferredDiagnosisCode,
  getDiagnosisDescription,
  getDiagnosisDescriptionV3,
} from 'utils/patientUtils';
import theme from 'theme';
import Tabs from 'pages/Dashboard/components/Tabs';
import { NavigateBefore } from '@mui/icons-material';
import { SourceTypeMap } from 'pages/Dashboard/types';
import { ReactComponent as User } from 'assets/UserIcon.svg';
import DiagnosisDrawer from 'pages/Dashboard/components/DiagnosisDrawer';
import { useParams } from 'react-router-dom';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';

export interface DiagnosisCardProps extends ShowMoreDrawerChildProps {
  diagnosis?: Diagnosis[];
  showTabs?: boolean;
  drawerTabIndex?: number;
  onShowMoreDiagnosis?: (d: Diagnosis[], idx?: number) => void;
}

interface DiagnosisDetailsProps extends BoxProps {
  diagnosis: Diagnosis[];
  children?: React.ReactNode;
  handleSelectedDiagnosis: (index: number) => void;
}

function DiagnosisDetails({
  diagnosis,
  children,
  handleSelectedDiagnosis,
  ...boxProps
}: DiagnosisDetailsProps) {
  const unknownDateDiagnosis = diagnosis.filter((current) => !current.started);

  return (
    <Box {...boxProps}>
      {diagnosis.length ? (
        <>
          {diagnosis.map((currentDiagnosis, index) => {
            if (!currentDiagnosis.started) return null;

            const isSameDate = googleDateToDateString(currentDiagnosis.onsetDate)
              === googleDateToDateString(currentDiagnosis.latestDate);
            const dateString = isSameDate ? googleDateToDateString(currentDiagnosis.onsetDate) : `${googleDateToDateString(currentDiagnosis.onsetDate)}${
              currentDiagnosis.latestDate
                ? ` - ${googleDateToDateString(currentDiagnosis.latestDate)}`
                : ''
            }`;
            const diagnosisCode = findPreferredDiagnosisCode(currentDiagnosis.diagnosis.codes);

            return (
              <React.Fragment key={`current-regimen-${currentDiagnosis.id}`}>
                <ButtonBase
                  key={`current-regimen-${currentDiagnosis.id}`}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    '&:hover': {
                      backgroundColor: theme.custom.backgroundColor,
                    },
                  }}
                  onClick={() => handleSelectedDiagnosis(index)}
                >
                  <Box
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      marginRight: 24,
                    }}
                  >
                    <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
                      {dateString}
                    </Typography>
                    {!!diagnosisCode && (
                      <Typography mb={2} textAlign='left' width='100%'>
                        {getDiagnosisDescriptionV3(
                          diagnosisCode,
                          currentDiagnosis.diagnosis?.displayName,
                        )}
                      </Typography>
                    )}
                    <DoctorText doctor={currentDiagnosis.doctor} />
                  </Box>
                  <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {currentDiagnosis.occurrenceCount > 1 ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '20px',
                          borderRadius: '64px',
                          backgroundColor: theme.custom.colors.backgroundColorSecondary,
                          color: theme.custom.colors.lightTextSecondary,
                          padding: '0 6.5px',
                          mr: 1,
                        }}
                      >
                        <Typography
                          color={theme.custom.colors.lightTextSecondary}
                          fontSize={12}
                          fontWeight={500}
                        >
                          {currentDiagnosis.occurrenceCount}
                        </Typography>
                      </Box>
                    ) : null}
                    {currentDiagnosis.source === SourceTypeMap.SOURCE_TYPE_PATIENT_REPORTED ? (
                      <Tooltip title='This item was added by patient' placement='top'>
                        <Box
                          sx={{
                            width: 20,
                            height: 20,
                            borderRadius: 20,
                            bgcolor: theme.custom.colors.backgroundColorSecondary,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <User />
                        </Box>
                      </Tooltip>
                    ) : null}
                    <NavigateBefore
                      sx={{
                        color: theme.custom.colors.lightTextSecondary,
                        marginLeft: 'auto',
                        marginRight: 0,
                        transform: 'rotate(180deg)',
                      }}
                    />
                  </Box>
                </ButtonBase>
              </React.Fragment>
            );
          })}
          {unknownDateDiagnosis.length ? (
            <>
              <Typography mb={2} mt={3} variant='body1' color={theme.custom.colors.lightTextSecondary}>
                Unknown dates
              </Typography>
              {unknownDateDiagnosis.map((unknownDiagnosis) => {
                const unknownDiagnosisCode = findPreferredDiagnosisCode(
                  unknownDiagnosis.diagnosis.codes,
                );
                if (!unknownDiagnosisCode) {
                  return null;
                }
                return (
                  <Typography mb={2} key={`unknown-diagnosis-${unknownDiagnosis?.id}`}>
                    {getDiagnosisDescription(
                      unknownDiagnosisCode,
                      unknownDiagnosis.diagnosis?.displayName,
                    )}
                  </Typography>
                );
              })}
            </>
          ) : null}
          {children}
        </>
      ) : (
        <NoData />
      )}
    </Box>
  );
}

export default function DiagnosisCardNew({
  diagnosis = [],
  showTabs,
  drawerTabIndex,
  drawerMode,
  closeDrawer,
  drawerItem,
  onShowMoreDiagnosis,
}: DiagnosisCardProps): JSX.Element | null {
  const { id } = useParams();
  const [value, setValue] = React.useState(0);
  const [psychDiagnosis, setPsychDiagnosis] = React.useState<Diagnosis[]>([]);
  const [otherDiagnosis, setOtherDiagnosis] = React.useState<Diagnosis[]>([]);
  const [finalDiagnosisList, setFinalDiagnosisList] = React.useState<Diagnosis[]>([]);
  const [currentDiagnosisList, setCurrentDiagnosisList] = React.useState<Diagnosis[]>([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = React.useState<Partial<Diagnosis>>();
  const [showDrawer, setShowDrawer] = React.useState(false);
  React.useEffect(() => {
    const nonDatedDiagnosis = diagnosis
      .filter((currentDiagnosis) => !currentDiagnosis.started)
      .sort((a, b) => getDiagnosisDescription(
        a.diagnosis.codes[0],
        a.diagnosis.displayName,
      ).localeCompare(
        getDiagnosisDescription(b.diagnosis.codes[0], b.diagnosis.displayName),
      ));
    const datedDiagnosis = diagnosis.filter((currentDiagnosis) => currentDiagnosis.started);
    const diagnosisList = [...datedDiagnosis, ...nonDatedDiagnosis];
    setFinalDiagnosisList(diagnosisList);
  }, [diagnosis]);
  const handleSelectedDiagnosis = (index: number) => {
    setSelectedDiagnosis(currentDiagnosisList?.[index]);
    trackMixpanelEvent(MixpanelEventName.DIAGNOSIS_CLICKED);
    toggleDrawer();
  };
  React.useEffect(() => {
    const psych = finalDiagnosisList?.filter(
      (currentDiagnosis) => currentDiagnosis
        .category
      === DiagnosisCategory.DIAGNOSIS_CATEGORY_PSYCH,
    );
    const others = finalDiagnosisList?.filter(
      (currentDiagnosis) => currentDiagnosis
        .category
      !== DiagnosisCategory.DIAGNOSIS_CATEGORY_PSYCH,
    );
    setPsychDiagnosis(psych);
    setOtherDiagnosis(others);
  }, [finalDiagnosisList]);
  React.useEffect(() => {
    setCurrentDiagnosisList(value === 0 ? psychDiagnosis : otherDiagnosis);
  }, [value, psychDiagnosis, otherDiagnosis]);
  const slicedDiagnosis = sliceForShowMore<Diagnosis>(finalDiagnosisList, !drawerMode, 6);

  const slicedCurrentDiagnosis = sliceForShowMore<Diagnosis>(currentDiagnosisList, !drawerMode, 6);
  const handleChangeTab = (tab: number) => {
    setValue(tab);
  };

  React.useEffect(() => {
    if (drawerMode) {
      setValue(drawerTabIndex || 0);
    }
  }, [drawerTabIndex, drawerMode]);
  const { Icon } = HealthRecordCategories.diagnosis.value;
  const toggleDrawer = () => {
    setShowDrawer((prev) => !prev);
  };
  const handleShowMoreDiagnosis = (diagnosisList: Diagnosis[], val?: number) => {
    trackMixpanelEvent(MixpanelEventName.SHOW_MORE_DIAGNOSIS_CLICKED);
    onShowMoreDiagnosis?.(diagnosisList, val);
  };
  const codes = findPreferredDiagnosisCode(selectedDiagnosis?.diagnosis?.codes);
  const displayDiagnosisName = codes
    ? getDiagnosisDescriptionV3(codes, selectedDiagnosis?.diagnosis?.displayName)
    : selectedDiagnosis?.diagnosis?.displayName;
  return diagnosis.length > 0 ? (
    <Grid item xs={12} md={!drawerMode ? 4 : undefined} key='current-regimen-diagnosis'>
      <Card sx={{ height: '100%' }}>
        {!drawerMode && (
          <CardHeader
            avatar={<Icon />}
            title='Diagnoses'
            titleTypographyProps={{ variant: 'h5', fontSize: 24, fontWeight: 400 }}
          />
        )}
        {drawerMode && (
          <ShowMoreDrawerTitle
            closeDrawer={closeDrawer as VoidFunction}
            drawerItem={drawerItem as string}
          />
        )}
        <CardContent>
          {showTabs ? (
            <>
              <Box mb={2}>
                <Tabs
                  tabItems={[
                    { label: `${TabLabels.PSYCH} (${psychDiagnosis.length})`, value: 0 },
                    { label: `${TabLabels.OTHER} (${otherDiagnosis.length})`, value: 1 },
                  ]}
                  selectedTab={value}
                  setSelectedTab={(tab) => handleChangeTab(tab)}
                />
              </Box>
              <DiagnosisDetails
                diagnosis={slicedCurrentDiagnosis}
                handleSelectedDiagnosis={handleSelectedDiagnosis}
              />
            </>
          ) : (
            <DiagnosisDetails
              diagnosis={drawerMode ? diagnosis : slicedDiagnosis}
              handleSelectedDiagnosis={handleSelectedDiagnosis}
            />
          )}
          <ShowMoreButton
            isDrawer={drawerMode}
            onShowMore={() => handleShowMoreDiagnosis?.(diagnosis, value)}
            items={currentDiagnosisList}
            showMoreThreshold={6}
          />
        </CardContent>
      </Card>
      <DiagnosisDrawer
        diagnosisName={displayDiagnosisName ?? ''}
        diagnosisId={selectedDiagnosis?.id ?? ''}
        toggleDrawer={toggleDrawer}
        patientId={id ?? ''}
        isOpen={showDrawer}
      />
    </Grid>
  ) : null;
}
