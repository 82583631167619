import { Box, Card, CardContent, Grid, Typography, CardHeader } from '@mui/material';
import NoDataForNow from 'pages/Dashboard/components/NoDataForNow';
import UserAddedToolTip from 'pages/Dashboard/components/UserAddedToolTip';
import HealthRecordCategories from 'pages/Dashboard/HealthRecordCategories';
import {
  ShowMoreButton,
  ShowMoreDrawerChildProps,
  ShowMoreDrawerTitle,
  sliceForShowMore,
} from 'pages/Dashboard/ShowMoreDrawer';
import { DisorderLethal, FamilyHistory } from 'pages/Dashboard/types/familyHistory.types';
import * as React from 'react';
import theme from 'theme';

export interface FamilyHistoryCardProps extends ShowMoreDrawerChildProps {
  familyHistory?: FamilyHistory[];
  isNewUi?:boolean
}

type GroupRelationType = { [key: string]: FamilyHistory[] | [] };
const sliceFamilyHistoryData = (familyHistory: FamilyHistory[]) => {
  const numberOfRecords = 14;
  let count = 0;
  const slicedFamilyHistoryData = familyHistory.map((history) => {
    if (count < numberOfRecords) {
      const newRecords = history.disorders.slice(0, numberOfRecords - count);
      count += newRecords.length;
      return { ...history, disorders: newRecords };
    }
    else return null;
  });
  const finalSlice = slicedFamilyHistoryData.filter((history) => history !== null);
  const groupByRelation = (finalSlice as FamilyHistory[]).reduce((acc: GroupRelationType, obj) => {
    const { relationText: k } = obj;
    const curGroup = acc[k] ?? [];
    return { ...acc, [k]: [...curGroup, obj] };
  }, {});
  return sliceForShowMore(Object.entries(groupByRelation), true);
};

export default function FamilyHistoryCard({
  closeDrawer,
  drawerItem,
  drawerMode = false,
  familyHistory = [],
  onShowMore,
  isNewUi = false,
}: FamilyHistoryCardProps): JSX.Element | null {
  const groupByRelation = familyHistory.reduce((acc: GroupRelationType, obj) => {
    const { relationText: k } = obj;
    const curGroup = acc[k] ?? [];
    return { ...acc, [k]: [...curGroup, obj] };
  }, {});

  const getDisorderType = (type: DisorderLethal) => {
    switch (type) {
      case DisorderLethal.DISORDER_LETHAL:
        return <Typography>(lethal)</Typography>;
      case DisorderLethal.DISORDER_NON_LETHAL:
        return <Typography>(non-lethal)</Typography>;
      default:
        return null;
    }
  };

  const data = sliceForShowMore(Object.entries(groupByRelation), !drawerMode);
  if (familyHistory.length === 0 && isNewUi) {
    return null;
  }
  const slicedData = sliceFamilyHistoryData(familyHistory);
  const { Icon } = HealthRecordCategories.familyHistory.value;
  const finalData = drawerMode ? data : slicedData;
  return (
    <Grid item xs={12} md={!drawerMode ? 4 : undefined} key='family-history'>
      <Card sx={{ height: '100%' }}>
        {!drawerMode && (
          <CardHeader
            avatar={<Icon />}
            title='Family history'
            titleTypographyProps={{ variant: 'h5', fontSize: 24, fontWeight: 400 }}
          />
        )}
        {drawerMode && <ShowMoreDrawerTitle closeDrawer={closeDrawer} drawerItem={drawerItem} />}
        <CardContent>
          {familyHistory.length ? (
            finalData.map((entries) => {
              const [relation, history] = entries;
              return (
                <Box key={`family-history-${history[0].relative.id}`} mb={2}>
                  <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
                    {relation}
                  </Typography>
                  {history.map((historyData) => historyData?.disorders.map((disorder) => (
                    <React.Fragment key={`disorder-${disorder.id}`}>
                      <Box display='flex' flexDirection='row' justifyContent='space-between'>
                        <Box display='flex' gap={1}>
                          <Typography>{disorder.name}</Typography>
                          {getDisorderType(disorder.lethal)}
                        </Box>
                        <UserAddedToolTip source={disorder.source} />
                      </Box>
                    </React.Fragment>
                  )))}
                </Box>
              );
            })
          ) : (
            <NoDataForNow />
          )}
          <ShowMoreButton
            isDrawer={drawerMode}
            onShowMore={onShowMore as VoidFunction}
            items={Object.entries(groupByRelation)}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}
