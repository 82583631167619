import { Card, CardContent, Grid, Typography, CardHeader, Box } from '@mui/material';
import NoData from 'pages/Dashboard/components/NoData';
import UserAddedToolTip from 'pages/Dashboard/components/UserAddedToolTip';
import HealthRecordCategories from 'pages/Dashboard/HealthRecordCategories';
import { ShowMoreButton, ShowMoreDrawerChildProps, ShowMoreDrawerTitle, sliceForShowMore } from 'pages/Dashboard/ShowMoreDrawer';
import { Allergy } from 'pages/Dashboard/types/allergies.types';
import * as React from 'react';
import theme from 'theme';
import { googleTimestampToDateString } from 'utils/dateUtils';


interface AllergiesCardProps extends ShowMoreDrawerChildProps{
  allergies?: Allergy[];
  isNewUi?:boolean
}

interface AllergyDetaisProps {
  allergies: Allergy[];
}

function AlleryDetails({ allergies }: AllergyDetaisProps) : JSX.Element {
  const nonDatedAllergies = allergies.filter((allergy) => !allergy.started);

  return (
    <Box display='flex' justifyContent='space-between' flexDirection='column' width='100%'>
      {
       allergies.map((currentAllergy) => {
         if (!currentAllergy.started) return null;
         return (
           <Box key={`health-history-${currentAllergy.id}`} display='flex' flexDirection='row' width='100%'>
             <Box display='flex' flexDirection='column' width='100%'>
               <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
                 {googleTimestampToDateString(currentAllergy.started)}
               </Typography>
               <Typography>{currentAllergy.substance}</Typography>
               <Typography variant='subtitle1' color={theme.custom.colors.lightTextSecondary} mb={2}>
                 {currentAllergy.reactions?.map((r) => `${r.name} ${r.severity ? `(${r.severity})` : ''}`).join(', ')}
               </Typography>
             </Box>
             <UserAddedToolTip source={currentAllergy.source} />
           </Box>
         );
       })
      }
      {nonDatedAllergies.length ? (
        <Box display='flex' justifyContent='space-between' flexDirection='column' width='100%'>
          <Typography mb={2} variant='body1' color={theme.custom.colors.lightTextSecondary}>
            Unknown dates
          </Typography>
          {
            nonDatedAllergies.map((currentAllergy) => (
              <Box key={`health-history-${currentAllergy.id}`} display='flex' flexDirection='row' width='100%'>
                <Box display='flex' flexDirection='column' width='100%'>
                  <Typography>{currentAllergy.substance}</Typography>
                  <Typography variant='subtitle1' color={theme.custom.colors.lightTextSecondary} mb={2}>
                    {currentAllergy.reactions?.map((r) => `${r.name} ${r.severity ? `(${r.severity})` : ''}`).join(', ')}
                  </Typography>
                </Box>
                <UserAddedToolTip source={currentAllergy.source} />
              </Box>
            ))
          }
        </Box>
      ) : null}
    </Box>
  );
}

export default function AllergiesCard({
  closeDrawer,
  drawerItem,
  drawerMode = false,
  allergies = [],
  onShowMore,
  isNewUi = false,
}: AllergiesCardProps): JSX.Element | null {
  if (allergies.length === 0 && isNewUi) return null;
  const datedAllergies = allergies.filter((allergy) => allergy.started);
  const nonDatedAllergies = allergies.filter((allergy) => !allergy.started)
    .sort((a, b) => (a.substance).localeCompare(b.substance));

  const sortedAllergies = [...datedAllergies, ...nonDatedAllergies];
  const data = sliceForShowMore(sortedAllergies, !drawerMode);
  const { Icon } = HealthRecordCategories.allergies.value;
  return (
    <Grid item xs={12} md={!drawerMode ? 4 : undefined} key='health-history-allergies'>
      <Card sx={{ height: '100%' }}>
        {!drawerMode && (
          <CardHeader
            avatar={<Icon />}
            title='Allergies'
            titleTypographyProps={{ variant: 'h5', fontSize: 24, fontWeight: 400 }}
          />
        )}
        {drawerMode && <ShowMoreDrawerTitle closeDrawer={closeDrawer} drawerItem={drawerItem} />}

        <CardContent>
          {!allergies.length ? (
            <NoData />
          ) : (
            <AlleryDetails allergies={data} />
          )}
          <ShowMoreButton
            isDrawer={drawerMode}
            onShowMore={onShowMore as VoidFunction}
            items={allergies}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}
