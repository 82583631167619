import { Box, Card, CardContent, Grid, Stack, Typography, CardHeader } from '@mui/material';
import HealthRecordCategories from 'pages/Dashboard/HealthRecordCategories';
import NoDataForNow from 'pages/Dashboard/components/NoDataForNow';
import { consumption } from 'pages/Dashboard/services/lifestyle.services';
import { LifeStyleCategory, PatientLifeStyle } from 'pages/Dashboard/types/lifestyle.types';
import * as React from 'react';
import theme from 'theme';
import { frequencyFormatter } from 'utils/dateUtils';

interface SubstanceCardProps {
  lifeStyle?: PatientLifeStyle[];
  isNewUi?:boolean;
}

export default function SubstanceCard({ lifeStyle = [], isNewUi = false }: SubstanceCardProps) {
  function renderLifeStyle(currentStyle: PatientLifeStyle) {
    const { alcohol, tobacco, caffeine, otherSubstance } = currentStyle;

    switch (currentStyle.category) {
      case LifeStyleCategory.LIFE_STYLE_CATEGORY_ALCOHOL:
        return (
          <Box key={LifeStyleCategory.LIFE_STYLE_CATEGORY_ALCOHOL}>
            <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>Alcohol</Typography>
            {
              alcohol?.consumption ? (
                <>
                  {consumption(alcohol) ? <Typography>{consumption(alcohol)}</Typography> : null}
                  {alcohol?.drinks.map((drink) => (
                    <Typography>
                      {`${drink.quantity} ${drink.unit} ${frequencyFormatter(
                        drink.frequency,
                      )}`}
                    </Typography>
                  ))}
                </>
              ) : 'None'
            }
          </Box>
        );
      case LifeStyleCategory.LIFE_STYLE_CATEGORY_TOBACCO:
        return (
          <Box key={LifeStyleCategory.LIFE_STYLE_CATEGORY_TOBACCO}>
            <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>Tobacco</Typography>
            <Typography>
              {tobacco?.consumption
                ? tobacco?.tobaccoForms?.map((forms, index) => {
                  if (index === tobacco.tobaccoForms.length - 1) { return `${forms.name}`; }
                  return `${forms.name}, `;
                })
                : 'None'}
            </Typography>
          </Box>
        );
      case LifeStyleCategory.LIFE_STYLE_CATEGORY_CAFFEINE:
        return (
          <Box key={LifeStyleCategory.LIFE_STYLE_CATEGORY_CAFFEINE}>
            <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>Caffeine</Typography>
            {caffeine?.consumption && consumption(caffeine)
              ? <Typography>{consumption(caffeine)}</Typography>
              : 'None'}
          </Box>
        );
      case LifeStyleCategory.LIFE_STYLE_CATEGORY_OTHER_SUBSTANCE:
        return (
          <Box key={LifeStyleCategory.LIFE_STYLE_CATEGORY_OTHER_SUBSTANCE}>
            <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>Other substances</Typography>
            {
              otherSubstance?.consumption
                ? otherSubstance?.substances.map((substance) => {
                  if (substance.quantity === 'Never') { return null; }
                  return (
                    <Typography>
                      {`${substance.name}${consumption(substance) ? ` ${consumption(substance)}` : ''}`}
                    </Typography>
                  );
                }) : 'None'
            }
            <Typography />
          </Box>
        );
    }
    return null;
  }
  const { Icon } = HealthRecordCategories.substance.value;
  if (lifeStyle.length === 0 && isNewUi) {
    return null;
  }
  return (
    <Grid item xs={12} md={4}>
      <Card sx={{ height: '100%' }}>
        <CardHeader
          avatar={<Icon />}
          title={HealthRecordCategories.substance.value.displayName}
          titleTypographyProps={{ variant: 'h5', fontSize: 24, fontWeight: 400 }}
        />
        <CardContent>
          {!lifeStyle.length ? (
            <NoDataForNow />
          ) : (
            <Stack spacing={2}>{lifeStyle.map((style) => renderLifeStyle(style))}</Stack>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}
