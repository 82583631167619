import * as React from 'react';
import { Button, CardHeader, Drawer, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

export interface ShowMoreDrawerTitleProps {
  drawerItem: string;
  closeDrawer: () => void;
}

export interface ShowMoreDrawerProps extends ShowMoreDrawerTitleProps {
  children: React.ReactNode;
}

export interface ShowMoreButtonProps {
  onShowMore: () => void;
  isDrawer: boolean; // true if drawer
  items: unknown[];
  showMoreThreshold?: number;
}

export interface ShowMoreDrawerChildProps extends ShowMoreDrawerTitleProps {
  onShowMore?: () => void;
  drawerMode: boolean;
}

export default function ShowMoreDrawer(props: ShowMoreDrawerProps) {
  const { drawerItem, closeDrawer, children } = props;

  return (
    <Drawer
      anchor='right'
      open={!!drawerItem}
      onClose={closeDrawer}
      PaperProps={{
        sx: {
          width: 600,
          minWidth: 600,
        },
      }}
    >
      {children}
    </Drawer>
  );
}

export function ShowMoreDrawerTitle(props: ShowMoreDrawerTitleProps) {
  const { drawerItem, closeDrawer } = props;

  return (
    <CardHeader
      action={(
        <IconButton onClick={closeDrawer}>
          <Close fontSize='small' />
        </IconButton>
      )}
      title={drawerItem}
    />
  );
}

export const SHOW_MORE_AFTER = 3;

export const SHOW_MORE_AFTER_MEDICATIONS = 6;

export function ShowMoreButton(props: ShowMoreButtonProps) {
  const { isDrawer, onShowMore, items, showMoreThreshold = SHOW_MORE_AFTER } = props;
  const showMore = items.length > showMoreThreshold;
  if (!isDrawer && onShowMore && showMore) {
    return (
      <Button sx={{ p: 0 }} onClick={onShowMore}>
        SHOW MORE
      </Button>
    );
  }

  return null;
}

export function sliceForShowMore<T>(
  items: T[],
  slice = false,
  showMoreThreshold = SHOW_MORE_AFTER,
) {
  return slice
    ? items.slice(0, showMoreThreshold)
    : items;
}
