import { useHttp } from 'hooks/use-fetch';
import { fetchUserMetadataWithKeys } from 'pages/Dashboard/services/provider.services';
import { UserMetadata, UserMetadataFlags } from 'pages/Dashboard/types';
import React from 'react';
import { useQuery } from 'react-query';
import ContentLoader from 'components/Common/ContentLoader';
import PatientDetail from 'pages/Dashboard/PatientDetail';
import PatientDetailNew from 'pages/Dashboard/PatientDetailNew';

export default function PatientDetailUIContainer() {
  const { providerId, http } = useHttp();
  const [isNewUi, setIsNewUi] = React.useState<boolean>(false);
  const [showLoader, setShowLoader] = React.useState<boolean>(true);
  useQuery({
    queryKey: [providerId, UserMetadataFlags.EnableNewPatientDetailsView],
    queryFn: fetchUserMetadataWithKeys(http.post),
    onSuccess: (data:UserMetadata) => {
      const isEnabled = data.flag.value === 'true';
      setIsNewUi(isEnabled);
      setShowLoader(false);
    },
    onError: () => {
      setIsNewUi(false);
      setShowLoader(false);
    },
  });
  return (
    <ContentLoader
      isFetching={showLoader}
      isError={false}
      minHeight={400}
    >
      {isNewUi ? <PatientDetailNew /> : <PatientDetail />}
    </ContentLoader>
  );
}
