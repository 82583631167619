import { Box, Card, CardContent, Grid, Typography, CardHeader } from '@mui/material';
import NoDataForNow from 'pages/Dashboard/components/NoDataForNow';
import Tabs from 'pages/Dashboard/components/Tabs';
import UserAddedToolTip from 'pages/Dashboard/components/UserAddedToolTip';
import HealthRecordCategories from 'pages/Dashboard/HealthRecordCategories';
import {
  ShowMoreButton,
  ShowMoreDrawerChildProps,
  ShowMoreDrawerTitle,
  sliceForShowMore,
} from 'pages/Dashboard/ShowMoreDrawer';
import {
  GroupedSymptoms,
  NestedSymptoms,
  PatientSymptom,
} from 'pages/Dashboard/types/symptoms.types';
import * as React from 'react';
import theme from 'theme';

enum SymptomsTabLabels {
  WITHIN_30_DAYS = 'WITHIN 30 DAYS',
  PRIOR = 'PRIOR',
}

export interface SymptomsCardProps extends ShowMoreDrawerChildProps {
  reportedSymptoms?: PatientSymptom[],
  reportedSymptomsHistory?: PatientSymptom[],
  onShowMoreSymptoms?:(idx?: number) => void;
  drawerTabIndex?: number;
  isNewUi?: boolean;
}

interface TabPanelsProps{
  children: React.ReactNode;
  groupedSymptom: GroupedSymptoms,
  value: number,
  index: number,
}

function SymptomsCardTabPanels(
  { children, groupedSymptom, value, index }: TabPanelsProps,
): JSX.Element | null {
  return (
    <Box hidden={value !== index}>
      { !Object.keys(groupedSymptom).length ? <NoDataForNow />
        : Object.keys(groupedSymptom).map((categoryKey) => (
          <React.Fragment key={`health-record-${categoryKey}`}>
            <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
              {categoryKey}
            </Typography>
            <ul>
              {
                groupedSymptom[categoryKey].map((data) => (
                  <li>
                    <Box display='flex' justifyContent='space-between'>
                      <Typography>{data.symptom.name}</Typography>
                      <UserAddedToolTip source={data.source} />
                    </Box>
                  </li>
                ))
              }
            </ul>
          </React.Fragment>
        ))}
      {children}
    </Box>
  );
}

export default function SymptomsCard({
  closeDrawer,
  drawerItem,
  drawerMode = false,
  onShowMoreSymptoms = () => null,
  drawerTabIndex,
  reportedSymptoms = [],
  reportedSymptomsHistory = [],
  isNewUi,
}: SymptomsCardProps): JSX.Element | null {
  const [value, setValue] = React.useState(0);

  const groupSymptomsByCategory = (slicedSymptoms: PatientSymptom[]) => {
    const symptomsData: GroupedSymptoms = {};

    slicedSymptoms.forEach((data) => {
      const {
        symptom: { category },
      } = data;
      const requiredData: NestedSymptoms = {
        symptom: data.symptom,
        startDate: data.startDate,
        source: data.source,
      };

      if (Object.hasOwn(symptomsData, category)) {
        symptomsData[category] = [...symptomsData[category], requiredData];
      }
      else {
        symptomsData[category] = [requiredData];
      }
    });
    return symptomsData;
  };

  const withinMonthSymptoms = reportedSymptoms.sort(
    (a, b) => a.symptom.category.localeCompare(b.symptom.category),
  );

  const priorSymptoms = reportedSymptomsHistory.sort(
    (a, b) => a.symptom.category.localeCompare(b.symptom.category),
  );

  const withinMonthSlice = sliceForShowMore(withinMonthSymptoms, !drawerMode);
  const priorSlice = sliceForShowMore(priorSymptoms, !drawerMode);

  const withinMonthGrpSymptoms = groupSymptomsByCategory(withinMonthSlice);
  const priorGrpSymptoms = groupSymptomsByCategory(priorSlice);
  const { Icon } = HealthRecordCategories.symptoms.value;
  React.useEffect(() => {
    if (drawerMode) { setValue(drawerTabIndex || 0); }
  }, [drawerTabIndex, drawerMode]);
  const symptomsPresent = reportedSymptoms.length > 0 || reportedSymptomsHistory.length > 0;
  const handleChangeTab = (tab:number) => {
    setValue(tab);
  };
  if (!symptomsPresent && isNewUi) {
    return null;
  }
  return (
    <Grid item xs={12} md={!drawerMode ? 4 : undefined} key='health-record-reported-condition'>
      <Card sx={{ height: '100%' }}>
        {!drawerMode && (
          <CardHeader
            avatar={<Icon />}
            title='Symptoms'
            titleTypographyProps={{ variant: 'h5', fontSize: 24, fontWeight: 400 }}
          />
        )}
        {drawerMode && <ShowMoreDrawerTitle closeDrawer={closeDrawer} drawerItem={drawerItem} />}
        <CardContent>
          { !reportedSymptoms.length && !reportedSymptomsHistory?.length ? <NoDataForNow />
            : (
              <>
                <Box mb={2}>
                  <Tabs
                    tabItems={[
                      { label: `${SymptomsTabLabels.WITHIN_30_DAYS} (${reportedSymptoms.length})`, value: 0 },
                      { label: `${SymptomsTabLabels.PRIOR} (${reportedSymptomsHistory.length})`, value: 1 },
                    ]}
                    selectedTab={value}
                    setSelectedTab={(tab) => handleChangeTab(tab)}
                  />
                </Box>
                <SymptomsCardTabPanels
                  groupedSymptom={withinMonthGrpSymptoms}
                  value={value}
                  index={0}
                >
                  <ShowMoreButton
                    isDrawer={drawerMode}
                    onShowMore={() => onShowMoreSymptoms(value)}
                    items={withinMonthSymptoms}
                  />
                </SymptomsCardTabPanels>
                <SymptomsCardTabPanels
                  groupedSymptom={priorGrpSymptoms}
                  value={value}
                  index={1}
                >
                  <ShowMoreButton
                    isDrawer={drawerMode}
                    onShowMore={() => onShowMoreSymptoms(value)}
                    items={priorSymptoms}
                  />
                </SymptomsCardTabPanels>
              </>
            )}
        </CardContent>
      </Card>
    </Grid>

  );
}
